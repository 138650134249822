import { animated } from '@react-spring/web'
import styled from 'styled-components/macro'
import { ExternalLink } from 'theme/components'

export const GeneratePanel = styled.div`
  position: relative;
`

export const TypeTabs = styled.div`
  display: flex;
  margin-left: 3.99vw;
  padding-top: 8.77vh;
`

export const TypeTab = styled.div<{ active: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 1.97vh;
  line-height: 2.42vh;
  text-align: center;
  padding: 0.98vh 1.16vw;
  margin-right: 3.3vw;
  cursor: pointer;

  color: ${({ theme, active }) => (active ? theme.text3 : theme.text1)};
  border-bottom: ${({ theme, active }) => (active ? `1px solid ${theme.text3}` : 'none')};
`

export const TypeDisableTab = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.97vh;
  line-height: 2.42vh;
  margin-right: 0.93vw;
  text-align: end;
  padding: 0.98vh 1.16vw;
  display: flex;
  align-items: center;

  color: ${({ theme }) => theme.text4};
`

export const ComingSoon = styled.span`
  background: #404040;
  border-radius: 8px;
  font-style: normal;
  font-weight: 300;
  font-size: 1.07vh;
  line-height: 1.43vh;
  margin-left: 0.29vw;
  padding: 0px 0.52vw;

  text-transform: uppercase;

  color: ${({ theme }) => theme.text2};
`

export const GenerateActionPanel = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0px 3.36vw;
`

export const ImageEnginePanel = styled.div`
  display: flex;
  margin-top: 1.34vh;
`

export const ImageEngineButton = styled.div<{ active: boolean; disabled: boolean }>`
  border: 1px solid ${({ theme, active }) => (active ? theme.text3 : theme.text2)};
  border-radius: 0.72vh;
  opacity: ${({ theme, disabled }) => (disabled ? '0.22' : '1')};
  width: 12.09vw;
  height: 2.6vh;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 400;
  font-size: 1.43vh;
  text-align: center;
  color: ${({ theme, active }) => (active ? theme.text3 : theme.text2)};
`

export const PromptPanel = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const GenerateLabel = styled.div<{ disabled: boolean }>`
  font-style: normal;
  font-weight: 700;
  font-size: 2.15vh;
  line-height: 2.86vh;

  text-transform: uppercase;
  margin-top: 3.49vh;

  color: ${({ theme, disabled }) => (disabled ? theme.text5 : theme.text2)};
`

export const AdjustIcon = styled.div`
  margin-left: 1.33vw;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 3.58vh;
    width: 3.58vh;
  }
`

export const ImageAdvancePanel = styled(animated.div)`
  height: 0;
`

export const ImageParamsPanel = styled.div`
  display: flex;
`

export const ParamItem = styled.div`
  flex: 1;
`

export const LoopItem = styled.div`
  flex: 3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 11.86vw;
  margin-top: 3.49vh;
  cursor: pointer;
`

export const MusicParamsPanel = styled.div`
  display: flex;
`

export const LoopIcon = styled.div`
  width: 2.69vh;
  height: 2.69vh;
`

export const ParamLabelPanel = styled.div`
  display: flex;
`

export const ParamValue = styled.div<{ disabled: boolean }>`
  font-style: normal;
  font-weight: 300;
  margin-top: 3.49vh;
  font-size: 1.43vh;

  color: ${({ theme, disabled }) => (disabled ? theme.text5 : theme.text3)}; ;
`

export const ParamSliderInfo = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;

  color: ${({ theme }) => theme.text4};
`

export const MinParam = styled.div`
  flex: 1;
  align-items: flex-start;
`

export const MaxParam = styled.div`
  flex: 1;
  align-items: flex-end;
  text-align: right;
`

export const GenerateButton = styled.div<{ generating: boolean; percent: number }>`
  cursor: pointer;
  border: ${({ theme, generating }) => (generating ? 'none' : `1px solid ${theme.text2}`)};
  border-radius: 8px;
  margin-top: 4.66vh;
  text-align: center;

  background: ${({ theme, generating, percent }) =>
    generating
      ? `linear-gradient(90deg, ${theme.text3} ${percent * 100}%, rgba(0, 255, 10, 0) ${percent * 100 + 20}%)`
      : theme.bg0};
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 3.22vh;
    padding: 1.07vh 0px;
    mix-blend-mode: difference;

    color: ${({ theme, generating }) => (generating ? theme.text3 : theme.text2)};
  }
`

export const GeneratedImagePanel = styled.div`
  margin-top: 4.48vh;
  display: flex;
  height: 34.85vh;
`

export const GeneratedImage = styled.div<{ lastOne: boolean }>`
  flex: 1;
  cursor: pointer;
  margin-right: ${({ lastOne }) => (lastOne ? '0px' : '1.16vw')};

  img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
`

export const GeneratedMusicPanel = styled.div`
  display: flex;
  margin-top: 4.48vh;
`

export const AudioPanel = styled.div`
  flex: 1;
  position: relative;
  display: flex;
`

export const MintMusic = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 2.86vh;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;
  cursor: pointer;

  margin-left: 4.4vw;
  margin-right: 1.85vw;

  color: ${({ theme }) => theme.text2};
`

export const GenDetailModal = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  height: fit-content;
  min-height: 85.68vh;
  background: rgba(12, 12, 12, 0.96);
  backdrop-filter: blur(2px);
`

export const GenDetailPanel = styled.div`
  margin: 0px 2.84vw;
  height: 100%;
  border: 1px solid rgba(250, 250, 250, 0.22);
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const DetailBackButton = styled.div`
  position: absolute;
  right: 1.91vw;
  top: 3.58vh;
  font-style: normal;
  font-weight: 300;
  font-size: 1.79vh;
  cursor: pointer;
  z-index: 1;

  text-decoration-line: underline;

  color: ${({ theme }) => theme.text2};
`

export const DetailInfoPanel = styled.div`
  margin: 4.83vh 1.91vw 4.83vh 2.31vw;
  flex: 1;
  display: flex;
`

export const DetailImagePanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 51.33vw;
  img {
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`

export const DetailMusicPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 51.33vw;
`

export const DetailRightPanel = styled.div`
  flex: 1;
  margin-left: 1.74vw;
  display: flex;
  flex-direction: column;
`

export const ImageInfoPanel = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
`

export const ImageInfoTitle = styled.div`
  color: ${({ theme }) => theme.text1};
`

export const ImageInfoContent = styled.div`
  color: ${({ theme }) => theme.text3};
`

export const Download = styled.div`
  margin-top: 1.61vh;

  font-style: normal;
  font-weight: 300;
  font-size: 1.79vh;
  line-height: 2.42vh;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.text2};
  cursor: pointer;
  a {
    font-style: normal;
    font-weight: 300;
    font-size: 1.79vh;
    line-height: 2.42vh;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.text2};
  }
`

export const ViewToken = styled.div`
  margin-top: 1.61vh;

  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  line-height: 1.88vh;
  color: ${({ theme }) => theme.text2};
`

export const MintInfoPanel = styled.div`
  margin-top: 3.4vh;
`

export const MintTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 4.3vh;
  line-height: 5.73vh;
  padding-bottom: 0.36vh;

  color: ${({ theme }) => theme.text2};
`

export const MintLabel = styled.div<{ disabled: boolean }>`
  font-style: normal;
  font-weight: 700;
  font-size: 1.79vh;
  line-height: 2.42vh;

  text-transform: uppercase;
  margin-top: 1.25vh;

  color: ${({ theme, disabled }) => (disabled ? theme.text5 : theme.text2)};
`

export const CountSpan = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  line-height: 1.88vh;

  color: ${({ theme }) => theme.text4};
`

export const MintInputError = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  line-height: 1.88vh;
  height: 1.88vh;
  margin-top: 0.9vh;

  color: ${({ theme }) => theme.red1};
`

export const PriceInfoPanel = styled.div<{ disabled: boolean }>`
  display: flex;
  opacity: ${({ disabled }) => (disabled ? '0.22' : '1')};
`

export const SellInputPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 3.7vw 1.7vh 0;
`

export const SellInput = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 1px solid #dad5d5;
  margin-top: 0.54vh;
`

export const PriceInput = styled.input`
  flex: 1;
  font-size: 2.15vh;
  outline: none;
  border: none;
  flex: 1 1 auto;
  width: 0;
  background-color: ${({ theme }) => theme.bg0};
  color: ${({ theme }) => theme.text1};
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
`

export const SymbolLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 2.15vh;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.text1};
`

export const PriceTip = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.25vh;
  max-width: 21.06vw;
  margin-bottom: 1.7vh;
  display: flex;
  align-items: flex-end;

  color: ${({ theme }) => theme.text3};
`

export const CheckInfoPanel = styled.div`
  margin-top: 1.7vh;
`

export const CheckItem = styled.div`
  display: flex;
  cursor: pointer;
`

export const CheckIcon = styled.div`
  width: 2.69vh;
  height: 2.69vh;
`

export const CheckInfo = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.25vh;
  margin-left: 0.64vw;

  color: ${({ theme }) => theme.text7};
`

export const MintActionPanel = styled.div`
  flex: 1;
  align-items: flex-end;
  display: flex;
`

export const MintButton = styled.div<{ disabled: boolean }>`
  border: 1px solid ${({ theme, disabled }) => (disabled ? theme.text5 : theme.text2)};
  border-radius: 0.72vh;
  font-style: normal;
  font-weight: 400;
  font-size: 1.79vh;
  line-height: 2.42vh;
  text-align: center;
  padding: 0.98vh 2.31vw;
  cursor: pointer;
  margin-top: 2.33vh;

  color: ${({ theme, disabled }) => (disabled ? theme.text5 : theme.text2)};
`

export const GenSuccessModal = styled.div`
  position: absolute;
  z-index: 2;
  top: 0.45vh;
  left: 13.54vw;
  width: fit-content;
  height: fit-content;
  background: rgba(12, 12, 12, 0.96);
  backdrop-filter: blur(2px);
  border: 1px solid rgba(250, 250, 250, 0.22);
  width: 72.97vw;

  border-radius: 8px;
`

export const MintSuccessPanel = styled.div`
  padding: 2.51vh 1.62vw;
  display: flex;
`

export const MintSuccessDetail = styled.div`
  flex: 1;
`

export const MintSuccessImagePanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  img {
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`

export const MintSuccessMusicPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
`

export const MintSuccessInfo = styled.div`
  flex: 1;
  margin-left: 2.26vw;
  margin-right: 2.49vw;
  margin-bottom: 6.27vh;
`

export const SuccessTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 8.59vh;
  line-height: 90.7%;
  margin-top: 4.39vh;

  color: ${({ theme }) => theme.text3};
`

export const SuccessInfo = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 3.22vh;
  line-height: 4.3vh;

  color: ${({ theme }) => theme.text1};
`

export const StyledInSucccessLink = styled.span`
  text-decoration: none;
  cursor: pointer;
  text-decoration: underline;
  font-style: normal;
  font-weight: 300;
  font-size: 3.22vh;
  line-height: 4.3vh;

  color: ${({ theme }) => theme.text1};
`

export const StyledInCreateLink = styled.span`
  text-decoration: none;
  cursor: pointer;
  text-decoration: underline;
  font-style: normal;
  font-weight: 300;
  font-size: 3.22vh;
  line-height: 4.3vh;

  color: ${({ theme }) => theme.text3};
`

export const StyledInLink = styled.span`
  text-decoration: none;
  cursor: pointer;
  text-decoration: underline;
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  line-height: 1.88vh;

  color: ${({ theme }) => theme.text1};
`

export const StyledExtLink = styled(ExternalLink)`
  text-decoration: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 300;
  font-size: 3.22vh;
  line-height: 4.3vh;

  color: ${({ theme }) => theme.text1};
  text-decoration: underline;
`

export const LikeListPanel = styled.div`
  margin: 7.43vh 3.53vw 0px 3.53vw;
  padding-bottom: 13.43vh;
`
export const LikeLabel = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 2.15vh;
  line-height: 2.86vh;

  text-transform: uppercase;
  margin-bottom: 2.86vh;

  color: ${({ theme }) => theme.text2};
`
