import CircelPause from 'assets/images/icon-circle-pause.png'
import CircelPlay from 'assets/images/icon-circle-play.png'
import useTheme from 'hooks/useTheme'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import WaveSurfer from 'wavesurfer.js'

const Player = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: #1e1e1e;
  // border-radius: 8px;
  width: 100%;
  height: 100%;
  svg {
    cursor: pointer;
  }
`

const Wave = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  wave {
    flex: 1;
  }
`

const PlayButton = styled.div<{ playSize: string }>`
  position: absolute;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: ${({ playSize }) => playSize};
    width: ${({ playSize }) => playSize};
  }
`

export default function AudioWavePlayer({ source, playSize }: { source: string; playSize: string }) {
  const theme = useTheme()
  const [play, setPlay] = useState(false)
  const audioRef = useRef(null as any)
  const waveRef = useRef(null as any)
  const [waveform, setWaveform] = useState(null as any)

  useEffect(() => {
    console.log('WaveSurfer.create')
    let observerRefValue = null as any
    let wave = null as any
    if (audioRef.current) {
      wave = WaveSurfer.create({
        barWidth: 2,
        barGap: 3,
        barMinHeight: 1,
        cursorWidth: 1,
        container: waveRef.current,
        backend: 'WebAudio',
        progressColor: theme.text3,
        responsive: true,
        waveColor: 'rgba(255, 255, 255, 0.06)',
        cursorColor: 'transparent'
      })
      wave.load(audioRef.current)
      wave.on('finish', () => {
        setPlay(false)
        if (wave) {
          wave.seekTo(0)
        }
      })
      setWaveform(wave)
      observerRefValue = wave
    }

    return () => {
      if (observerRefValue) observerRefValue.unAll()
      if (wave) {
        wave.stop()
      }
    }
  }, [theme.text2, theme.text3])

  const handlePlay = useCallback(
    (e) => {
      e.stopPropagation()
      if (play) {
        setPlay(false)
      } else {
        setPlay(true)
      }
      if (waveform) waveform.playPause()
    },
    [play, waveform]
  )

  return (
    <Player>
      <Wave ref={waveRef} />
      <audio crossOrigin={'*'} ref={audioRef} src={source} />
      <PlayButton playSize={playSize}>
        {play ? (
          <img onClick={handlePlay} src={CircelPause} alt="play"></img>
        ) : (
          <img onClick={handlePlay} src={CircelPlay} alt="play"></img>
        )}
      </PlayButton>
    </Player>
  )
}
