import ArrowTop from 'assets/images/icon-arrow-top.png'
import styled from 'styled-components/macro'

const BackToTopPanel = styled.div`
  position: fixed;
  bottom: 181px;
  right: 66px;
  display: flex;
  cursor: pointer;
  mix-blend-mode: difference;
`

const ArrowIcon = styled.div`
  height: 16px;

  img {
    height: 100%;
  }
`

const Content = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;

  margin-left: 9px;

  color: ${({ theme }) => theme.text2};
`

export default function BackToTop({ handleBackToTop }: { handleBackToTop: () => void }) {
  return (
    <BackToTopPanel onClick={handleBackToTop}>
      <ArrowIcon>
        <img src={ArrowTop} alt={'arrow-top'}></img>
      </ArrowIcon>
      <Content>BACK TO TOP</Content>
    </BackToTopPanel>
  )
}
