import { useWeb3React } from '@web3-react/core'
import NFTItem from 'components/NFTItem'
import { CustomizeEvent } from 'constants/events'
import { useWindowSize } from 'hooks/useWindowSize'
import * as S from 'pages/MyNfts/styles'
import { useCallback, useEffect, useRef, useState } from 'react'
// import InfiniteScroll from 'react-infinite-scroller'
import Masonry from 'react-responsive-masonry'
import { useHistory } from 'react-router-dom'
import { useGetNftsListMutation } from 'state/ai/slice'
import { useAddPopup } from 'state/application/hooks'
import { MEDIA_WIDTHS, PageWrapper } from 'theme'
import useBus from 'use-bus'

export default function MyNFTs() {
  const pageSize = 24
  const history = useHistory()
  const { width } = useWindowSize()
  const { account } = useWeb3React()
  const [cursor, serCursor] = useState('')
  const throttling = useRef(false)
  const [hasMore, setHasMore] = useState(true)
  const [projects, setProjects] = useState([] as any[])

  const addPopup = useAddPopup()

  const [getNftList] = useGetNftsListMutation()

  const loadData = useCallback(
    (current: string) => {
      getNftList({ afterCursor: current, pageSize, orderBy: 'DESC', type: 'all', userId: account })
        .then((data: any) => {
          throttling.current = false
          console.log('ailist', data, data?.data?.data?.data.length)
          if (data?.data?.code === 0) {
            serCursor(data?.data?.data?.afterCursor)
            if (current) {
              setProjects(projects.concat(data?.data?.data?.data))
            } else {
              setProjects([].concat(data?.data?.data?.data))
            }
            if (data?.data?.data?.data.length < pageSize) {
              setHasMore(false)
              console.log('set hasmore false')
            }
          } else {
            addPopup({ error: 'Get AI list failed, please retry.' })
          }
        })
        .catch((e) => {
          console.error(e)
          throttling.current = false
          addPopup({ error: 'Get AI list failed, please retry.' })
        })
    },
    [account, addPopup, getNftList, projects]
  )

  useEffect(() => {
    if (account) {
      serCursor('')
      loadData('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const LoadMore = useCallback(() => {
    if (throttling.current || !hasMore) {
      return
    }
    throttling.current = true
    setTimeout(() => {
      loadData(cursor)
    }, 200)
  }, [cursor, hasMore, loadData])

  useBus(
    CustomizeEvent.SCROLL_TO_BOTTOM,
    () => {
      console.log('scroll to bottom')
      LoadMore()
    },
    [cursor, hasMore, loadData]
  )

  return (
    <PageWrapper>
      <S.Content>
        <S.MarqueenHeader speed={100} gradient={false}>
          My NFTs<span style={{ width: '26.22vw' }}></span>My NFTs<span style={{ width: '26.22vw' }}></span>My NFTs
          <span style={{ width: '26.22vw' }}></span>
        </S.MarqueenHeader>
        <S.ListPanel>
          {/* <InfiniteScroll
            initialLoad={false}
            pageStart={1}
            threshold={250}
            loadMore={() => {
              console.log('loadmore')
              if (throttling.current || !hasMore) {
                return
              }
              throttling.current = true
              setTimeout(() => {
                loadData(cursor)
              }, 200)
            }}
            hasMore={hasMore}
            useWindow={true}
            loader={<div key={2}></div>}
          > */}
          <Masonry columnsCount={width && width <= MEDIA_WIDTHS.upToSmall ? 1 : 3} gutter="2.31vw" key={1}>
            {projects &&
              projects.length > 0 &&
              projects.map((item: any, index: any) => {
                return (
                  <NFTItem
                    key={index}
                    image={item.tokenUrl}
                    forSale={
                      !item?.orders ||
                      item.orders.length <= 0 ||
                      item.orders[item.orders.length - 1]?.status === 'unSell'
                    }
                    price={
                      item.orders && item.orders.length > 0 && item.orders[item.orders.length - 1]?.status === 'onSell'
                        ? item.orders[0].context.currentPrice
                        : ''
                    }
                    onClick={() => {
                      console.log('mediaId', item.medias[0].mediaId)
                      history.push(`/detail/${item.medias[0].mediaId}`)
                    }}
                  ></NFTItem>
                )
              })}
          </Masonry>
          {/* </InfiniteScroll> */}
        </S.ListPanel>
      </S.Content>
    </PageWrapper>
  )
}
