import { useAnalyticsReporter } from 'components/Analytics'
import BackToTop from 'components/BackToTop'
import ErrorBoundary from 'components/ErrorBoundary'
import Footer from 'components/Footer'
import Header from 'components/Header'
import Loader from 'components/Loader'
import Popups from 'components/Popups'
import TopLevelModals from 'components/TopLevelModals'
import { CustomizeEvent } from 'constants/events'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import About from 'pages/About'
import AppBody from 'pages/AppBody'
import Collection from 'pages/Collection'
import Create from 'pages/Create'
import History from 'pages/History'
import Home from 'pages/Home'
import { RedirectPathToHomeOnly } from 'pages/Home/redirects'
import MyArtwork from 'pages/MyArtwork'
import MyNFTs from 'pages/MyNfts'
import NFTDetail from 'pages/NFTDetail'
import Sell from 'pages/Sell'
import Submit from 'pages/Submit'
import { Suspense, useCallback, useMemo, useRef, useState } from 'react'
import { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import DarkModeQueryParamReader from 'theme/DarkModeQueryParamReader'
import { dispatch as dispatchCustomEvent } from 'use-bus'

const Main = styled.div`
  flex: 1;
`

export default function App() {
  const history = useHistory()
  const location = useLocation()
  const scrollRef = useRef(null as any)
  useAnalyticsReporter(location)
  const path = useMemo(() => {
    return location.pathname
  }, [location.pathname])

  const [showBack, setShowBack] = useState(false)

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])
  const handleScroll = useCallback((e: any) => {
    if (
      e.target.scrollHeight - e.target.scrollTop - e.target.offsetHeight <= 0 &&
      e.target.scrollHeight > document.body.clientHeight
    ) {
      console.log('shwo back to top')
      setShowBack(true)
      dispatchCustomEvent(CustomizeEvent.SCROLL_TO_BOTTOM)
    } else {
      setShowBack(false)
    }
  }, [])

  const handleBackToTop = useCallback(() => {
    if (scrollRef.current)
      scrollRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
  }, [])

  return (
    <ErrorBoundary>
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <AppBody scrollRef={scrollRef} onScroll={handleScroll}>
        <Popups />
        <TopLevelModals />
        <Header />
        <Main>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route exact strict path="/" component={Home} />
              <Route exact strict path="/about" component={About} />
              <Route exact strict path="/create" component={Create} />
              <Route exact strict path="/submit" component={Submit} />
              <Route exact strict path="/collection" component={Collection} />
              <Route exact strict path="/myartwork" component={MyArtwork} />
              <Route exact strict path="/sell" component={Sell} />
              <Route exact strict path="/mynfs" component={MyNFTs} />
              <Route exact strict path="/history" component={History} />
              <Route exact strict path="/detail/:mediaId" component={NFTDetail} />

              <Route component={RedirectPathToHomeOnly} />
            </Switch>
          </Suspense>
          {showBack && path !== '/' && <BackToTop handleBackToTop={handleBackToTop} />}
        </Main>
        <Footer></Footer>
      </AppBody>
    </ErrorBoundary>
  )
}
