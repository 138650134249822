export enum AIGenerateType {
  IMAGE = 0,
  THREE_D = 1,
  MUSIC = 2,
  VIDEO = 3,
  VR = 4
}

export enum AIImageEngine {
  SD = 0,
  OPENAI = 1
}
