import { createSlice, nanoid } from '@reduxjs/toolkit'
import { DEFAULT_TXN_DISMISS_MS } from 'constants/misc'
import { OpenSeaPort } from 'opensea-js'

export type PopupContent =
  | {
      txn: {
        hash: string
      }
    }
  | {
      tip: string
    }
  | {
      error: string
    }

export enum ApplicationModal {
  ADDRESS_CLAIM,
  BLOCKED_ACCOUNT,
  DELEGATE,
  CLAIM_POPUP,
  MENU,
  NETWORK_SELECTOR,
  POOL_OVERVIEW_OPTIONS,
  PRIVACY_POLICY,
  SELF_CLAIM,
  SETTINGS,
  VOTE,
  WALLET,
  QUEUE,
  EXECUTE,
  BUILD,
  SAFE_MINT,
  NEW_TIMER,
  NEW_CONFIG,
  PROFILE_MENU
}

type PopupList = Array<{ key: string; show: boolean; content: PopupContent; removeAfterMs: number | null }>

export interface ApplicationState {
  readonly chainId: number | null
  readonly openModal: ApplicationModal | null
  readonly popupList: PopupList
  openSeaport: OpenSeaPort | null
}

const initialState: ApplicationState = {
  chainId: null,
  openModal: null,
  popupList: [],
  openSeaport: null
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateChainId(state, action) {
      const { chainId } = action.payload
      state.chainId = chainId
    },
    updateOpenseaPort(state, action) {
      const { openSeaPort } = action.payload
      state.openSeaport = openSeaPort
    },
    setOpenModal(state, action) {
      state.openModal = action.payload
    },
    addPopup(state, { payload: { content, key, removeAfterMs = DEFAULT_TXN_DISMISS_MS } }) {
      state.popupList = (key ? state.popupList.filter((popup) => popup.key !== key) : state.popupList).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs
        }
      ])
    },
    removePopup(state, { payload: { key } }) {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false
        }
      })
    }
  }
})

export const { updateChainId, setOpenModal, addPopup, removePopup, updateOpenseaPort } = applicationSlice.actions
export default applicationSlice.reducer
