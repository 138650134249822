import { useWeb3React } from '@web3-react/core'
import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'

export default function useAccess() {
  const { account } = useWeb3React()
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)

  return useMemo(() => {
    if (account) {
      if (currentUserInfo && currentUserInfo?.account === account) {
        console.log('the same account')
        if (currentUserInfo.accessExpire > new Date().getTime() / 1000) {
          console.log('no Expire')
          return true
        }
      }
    }
    return false
  }, [account, currentUserInfo])
}
