import { animated, useSpring } from '@react-spring/web'
import DefaultImage from 'assets/images/default-image.png'
import AudioWavePlayer from 'components/AudioWavePlayer'
import { useRef, useState } from 'react'
import styled from 'styled-components/macro'

const CardPanel = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  counter-increment: item-counter;
`

const CardImage = styled(animated.img)`
  padding: 0px;
  cursor: pointer;
  max-width: 100%;
  width: 100%;
`

const CardMusic = styled.div`
  padding: 0px;
  cursor: pointer;
  width: 100%;
  height: 100%;
`

export default function HistoryItem({ source, onClick }: { source: any; onClick?: () => void }) {
  const [isHovering, setIsHovering] = useState(false)
  const Img = useRef<HTMLImageElement>()
  const { scale, opacity } = useSpring({
    from: { scale: 1, opacity: 1 },
    scale: isHovering ? 0.99 : 1,
    opacity: isHovering ? 0.12 : 1,
    config: { duration: 200 }
  })

  return (
    <CardPanel
      onMouseEnter={() => {
        setIsHovering(true)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
      }}
      onClick={onClick}
    >
      {source?.type === 'image' && (
        <CardImage
          ref={Img as any}
          style={{ scale, opacity }}
          src={source?.data?.url}
          loading="lazy"
          alt=""
          onError={() => {
            if (Img.current) {
              Img.current.src = DefaultImage
            }
          }}
        />
      )}
      {source?.type === 'audio' && (
        <CardMusic>
          <AudioWavePlayer playSize={'5.1vh'} source={source?.data?.url} />
        </CardMusic>
      )}
    </CardPanel>
  )
}
