import { useWeb3React } from '@web3-react/core'
import AudioWavePlayer from 'components/AudioWavePlayer'
import Loading from 'components/Loading/Loading'
import { SupportedChainId } from 'constants/chains'
import { PublicUrl } from 'constants/publicurl'
import { useFetchTokenUriDataCallback } from 'hooks/useFetchDaoData'
import moment from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

const TokenPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: fit-content;
`
const ImagePanel = styled.div`
  height: 45.84vh;
  min-width: 24.51vw;
  display: flex;
  flex-direction: column;
  position: relative;
`

const Image = styled.img`
  height: 100%;
`

const Title = styled.div`
  margin-top: 1.61vh;
  font-style: normal;
  font-weight: 700;
  font-size: 2.15vh;
  line-height: 2.86vh;
  text-transform: uppercase;
`

const Time = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  line-height: 1.88vh;
  text-transform: uppercase;
  display: flex;
  position: relative;
  width: fit-content;
`

// const GMTTime = styled.div`
//   height: 100%;
//   font-style: normal;
//   font-weight: 300;
//   font-size: 0.72vh;
//   line-height: 1.34vh;
//   margin-left: 0.29vw;
//   text-transform: uppercase;
//   display: flex;
//   flex: column;
//   align-items: flex-start;
//   width: fit-content;
// `

export default function TokenImage({ uri, onLoad }: { uri: string; onLoad?: (uri: string) => void }) {
  const fetchData = useFetchTokenUriDataCallback()
  const [image, setImage] = useState('')
  const [detail, setDetail] = useState(null as any)
  const [showLoading, setShowLoading] = useState(true)
  const { chainId } = useWeb3React()

  useEffect(() => {
    console.log('uri', uri)
    fetchData(uri)
      .then((data) => {
        console.log('detail', data)
        setDetail(data)
        if (data.type === 'image') {
          setImage(data.url)
        } else {
          setShowLoading(false)
        }
      })
      .catch((error) => {
        console.debug('fetching data error', error)
      })
  }, [fetchData, uri])

  return (
    <>
      {detail && (
        <TokenPanel>
          {detail?.type === 'image' && (
            <ImagePanel>
              <Image
                src={image}
                loading="lazy"
                onLoad={() => {
                  console.log('onLoad')
                  if (showLoading) setShowLoading(false)
                  if (onLoad) onLoad(uri)
                }}
                onError={() => {
                  console.log('onError')
                  setImage(PublicUrl(chainId ?? SupportedChainId.MAINNET) + '/images/default-image.png')
                }}
                alt={uri}
              />
              {showLoading && <Loading></Loading>}
            </ImagePanel>
          )}
          {detail?.type === 'audio' && (
            <div style={{ height: '100%', minWidth: '24.51vw' }}>
              <AudioWavePlayer playSize={'5.1vh'} source={detail?.url} />
            </div>
          )}
          <Title>{detail.name}</Title>
          <Time>
            {moment(detail.generate_info.timestamp).format('DD MMM yyyy, HH:mm ')}
            {/* <GMTTime>{moment(detail.generate_info.timestamp).format('[GMT]ZZ').slice(0, -2)}</GMTTime> */}
          </Time>
        </TokenPanel>
      )}
    </>
  )
}
