import { useWeb3React } from '@web3-react/core'
import ArrowLeft from 'assets/images/icon-arrow-left.png'
import ArrowRight from 'assets/images/icon-arrow-right.png'
import HorizontalHistoryItem from 'components/HorizontalHistoryItem'
import HorizontalNFTItem from 'components/HorizontalNFTItem'
import * as S from 'pages/MyArtwork/styles'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useGetAIListMutation, useGetNftsListMutation } from 'state/ai/slice'
import { useAddPopup } from 'state/application/hooks'
import { PageWrapper } from 'theme'

export default function MyArtwork() {
  const history = useHistory()
  const { account } = useWeb3React()
  const pageSize = 10
  const [nfts, setNfts] = useState([] as any[])
  const [historys, setHistorys] = useState([] as any[])

  const addPopup = useAddPopup()

  const [getAIList] = useGetAIListMutation()
  const [getNftList] = useGetNftsListMutation()

  const nftsScroll = useRef(null as any)
  const historyScroll = useRef(null as any)

  const [curIndex, setCurIndex] = useState(0)
  const [lastAction, setLastAction] = useState(0) // 0 initail 1 pre 2 next

  const [curHistoryIndex, setCurHistoryIndex] = useState(0)
  const [lastHistoryAction, setLastHistoryAction] = useState(0) // 0 initail 1 pre 2 next

  const getMyNfts = useCallback(() => {
    getNftList({ pageSize, type: 'all', orderBy: 'DESC', userId: account })
      .then((data: any) => {
        console.log('nft list', data, data?.data?.data?.data.length)
        if (data?.data?.code === 0) {
          setNfts(data?.data?.data?.data)
        } else {
          addPopup({ error: 'Get AI list failed, please retry.' })
        }
      })
      .catch((e) => {
        console.error(e)
        addPopup({ error: 'Get AI list failed, please retry.' })
      })
  }, [account, addPopup, getNftList])

  const getHistory = useCallback(() => {
    getAIList({ pageSize, orderBy: 'DESC', resourceType: 'allMedia', search: account })
      .then((data: any) => {
        console.log('history list', data, data?.data?.data?.data.length)
        if (data?.data?.code === 0) {
          setHistorys(data?.data?.data?.data)
        } else {
          addPopup({ error: 'Get AI list failed, please retry.' })
        }
      })
      .catch((e) => {
        console.error(e)
        addPopup({ error: 'Get AI list failed, please retry.' })
      })
  }, [account, addPopup, getAIList])

  useEffect(() => {
    if (account) {
      getMyNfts()
      getHistory()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const handleNftNext = useCallback(() => {
    console.log('handleNext')
    if (lastAction === 0 || lastAction === 1) {
      let steps = curIndex + 7

      if (steps >= nfts.length - 1) {
        steps = nfts.length - 1
        setCurIndex(steps)
        setLastAction(2)

        nftsScroll.current.scrollTo({
          left: nftsScroll.current.scrollWidth,
          behavior: 'smooth'
        })
        return
      }
      console.log('steps', steps)
      const element = document.getElementById(`section-${steps}`)
      setCurIndex(steps)
      if (element) {
        setLastAction(2)
        console.log('scrollIntoView', `section-${steps}`)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
    } else {
      let steps = curIndex + 4
      if (steps >= nfts.length - 1) {
        steps = nfts.length - 1
        setCurIndex(steps)
        setLastAction(2)

        nftsScroll.current.scrollTo({
          left: nftsScroll.current.scrollWidth,
          behavior: 'smooth'
        })
        return
      }
      console.log('steps', steps)
      const element = document.getElementById(`section-${steps}`)
      setCurIndex(steps)
      if (element) {
        setLastAction(2)
        console.log('scrollIntoView', `section-${steps}`)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
    }
  }, [curIndex, lastAction, nfts.length])

  const handleNftPre = useCallback(() => {
    console.log('handlePre')
    if (lastAction === 0 || lastAction === 2) {
      let steps = curIndex - 7
      if (steps <= 0) {
        steps = 0
        setCurIndex(steps)
        setLastAction(1)
        nftsScroll.current.scrollTo({
          left: 0,
          behavior: 'smooth'
        })
        return
      }
      console.log('steps', steps)
      const element = document.getElementById(`section-${steps}`)
      setCurIndex(steps)
      if (element) {
        setLastAction(1)
        console.log('scrollIntoView', `section-${steps}`)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
    } else {
      let steps = curIndex - 4
      if (steps <= 0) {
        steps = 0
        setCurIndex(steps)
        setLastAction(1)
        nftsScroll.current.scrollTo({
          left: 0,
          behavior: 'smooth'
        })
        return
      }
      console.log('steps', steps)
      const element = document.getElementById(`section-${steps}`)
      setCurIndex(steps)
      if (element) {
        setLastAction(1)
        console.log('scrollIntoView', `section-${steps}`)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
    }
  }, [curIndex, lastAction])

  const handleHistoryNext = useCallback(() => {
    console.log('handleNext')
    if (lastHistoryAction === 0 || lastHistoryAction === 1) {
      let steps = curHistoryIndex + 7
      if (steps >= historys.length - 1) {
        steps = historys.length - 1
        setCurHistoryIndex(steps)
        setLastHistoryAction(2)

        historyScroll.current.scrollTo({
          left: historyScroll.current.scrollWidth,
          behavior: 'smooth'
        })
        return
      }
      console.log('steps', steps)
      const element = document.getElementById(`history-${steps}`)
      setCurHistoryIndex(steps)
      if (element) {
        setLastHistoryAction(2)
        console.log('scrollIntoView', `history-${steps}`)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
    } else {
      let steps = curHistoryIndex + 4
      if (steps >= historys.length - 1) {
        steps = historys.length - 1
        setCurHistoryIndex(steps)
        setLastHistoryAction(2)

        historyScroll.current.scrollTo({
          left: historyScroll.current.scrollWidth,
          behavior: 'smooth'
        })
        return
      }
      console.log('steps', steps)
      const element = document.getElementById(`history-${steps}`)
      setCurHistoryIndex(steps)
      if (element) {
        setLastHistoryAction(2)
        console.log('scrollIntoView', `history-${steps}`)
        // 👇 Will scroll smoothly to the top of the next history
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
    }
  }, [lastHistoryAction, curHistoryIndex, historys.length])

  const handleHistoryPre = useCallback(() => {
    console.log('handlePre')
    if (lastHistoryAction === 0 || lastHistoryAction === 2) {
      let steps = curHistoryIndex - 7
      if (steps <= 0) {
        steps = 0
        setCurHistoryIndex(steps)
        setLastHistoryAction(1)
        historyScroll.current.scrollTo({
          left: 0,
          behavior: 'smooth'
        })
        return
      }
      console.log('steps', steps)
      const element = document.getElementById(`history-${steps}`)
      setCurHistoryIndex(steps)
      if (element) {
        setLastHistoryAction(1)
        console.log('scrollIntoView', `history-${steps}`)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
    } else {
      let steps = curHistoryIndex - 4

      if (steps <= 0) {
        steps = 0
        setCurHistoryIndex(steps)
        setLastHistoryAction(1)
        historyScroll.current.scrollTo({
          left: 0,
          behavior: 'smooth'
        })
        return
      }
      console.log('steps', steps)
      const element = document.getElementById(`history-${steps}`)
      setCurHistoryIndex(steps)
      if (element) {
        setLastHistoryAction(1)
        console.log('scrollIntoView', `history-${steps}`)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
    }
  }, [curHistoryIndex, lastHistoryAction])

  return (
    <PageWrapper>
      <S.Content>
        <S.MarqueenHeader speed={100} gradient={false}>
          My Artwork<span style={{ width: '19.21vw' }}></span>My Artwork<span style={{ width: '19.21vw' }}></span>My
          Artwork<span style={{ width: '19.21vw' }}></span>
        </S.MarqueenHeader>
        <S.ArtworkHeader style={{ marginTop: '3.94vh' }}>
          <S.HeaderLabel>My NFTs</S.HeaderLabel>
          <S.ViewMore
            onClick={() => {
              history.push('/mynfs')
            }}
          >
            View more
          </S.ViewMore>
        </S.ArtworkHeader>
        {nfts && nfts.length > 0 && (
          <S.ListPanel>
            {curIndex !== 0 && (
              <S.ArrowLeftPanel>
                <S.ArrowIcon onClick={handleNftPre} src={ArrowLeft} alt={'arrow-left'}></S.ArrowIcon>
              </S.ArrowLeftPanel>
            )}
            <S.StyledScroll
              ref={nftsScroll}
              onScroll={(e) => {
                if (e.currentTarget.scrollLeft > 0) {
                  if (e.currentTarget.scrollLeft + e.currentTarget.clientWidth >= e.currentTarget.scrollWidth) {
                    setCurIndex(nfts.length - 1)
                  } else {
                    for (let i = 0; i < nfts.length; i++) {
                      const temp = document.getElementById(`section-${i}`)
                      if (temp) {
                        if (temp?.offsetLeft >= e.currentTarget.scrollLeft) {
                          setCurIndex(i)
                          break
                        }
                      }
                    }
                  }

                  setLastAction(1)
                }
              }}
            >
              {nfts &&
                nfts.length > 0 &&
                nfts.map((item: any, index: any) => {
                  return (
                    <S.ListItem key={index} id={`section-${index}`}>
                      <HorizontalNFTItem
                        image={item.tokenUrl}
                        price={
                          item.orders &&
                          item.orders.length > 0 &&
                          item.orders[item.orders.length - 1]?.status === 'onSell'
                            ? item.orders[0].context.currentPrice
                            : ''
                        }
                        forSale={
                          !item?.orders ||
                          item.orders.length <= 0 ||
                          item.orders[item.orders.length - 1]?.status === 'unSell'
                        }
                        onClick={() => {
                          console.log('mediaId', item.medias[0].mediaId)
                          history.push(`/detail/${item.medias[0].mediaId}`)
                        }}
                      ></HorizontalNFTItem>
                    </S.ListItem>
                  )
                })}
            </S.StyledScroll>
            {curIndex !== nfts.length - 1 &&
              nftsScroll.current &&
              nftsScroll.current.scrollWidth > nftsScroll.current.clientWidth && (
                <S.ArrowRightPanel>
                  <S.ArrowIcon onClick={handleNftNext} src={ArrowRight} alt={'arrow-right'}></S.ArrowIcon>
                </S.ArrowRightPanel>
              )}
          </S.ListPanel>
        )}
        <S.ArtworkHeader style={{ marginTop: '7.88vh' }}>
          <S.HeaderLabel>History</S.HeaderLabel>
          <S.ViewMore
            onClick={() => {
              history.push('/history')
            }}
          >
            View more
          </S.ViewMore>
        </S.ArtworkHeader>
        {historys && historys.length > 0 && (
          <S.ListPanel>
            {curHistoryIndex !== 0 && (
              <S.ArrowLeftPanel>
                <S.ArrowIcon onClick={handleHistoryPre} src={ArrowLeft} alt={'arrow-left'}></S.ArrowIcon>
              </S.ArrowLeftPanel>
            )}
            <S.StyledScroll
              ref={historyScroll}
              onScroll={(e) => {
                if (e.currentTarget.scrollLeft > 0) {
                  if (e.currentTarget.scrollLeft + e.currentTarget.clientWidth >= e.currentTarget.scrollWidth) {
                    setCurHistoryIndex(historys.length - 1)
                  } else {
                    for (let i = 0; i < historys.length; i++) {
                      const temp = document.getElementById(`section-${i}`)
                      if (temp) {
                        if (temp?.offsetLeft >= e.currentTarget.scrollLeft) {
                          setCurHistoryIndex(i)
                          break
                        }
                      }
                    }
                  }

                  setLastHistoryAction(1)
                }
              }}
            >
              {historys &&
                historys.length > 0 &&
                historys.map((item: any, index: any) => {
                  return (
                    <S.ListItem key={index} id={`history-${index}`}>
                      <HorizontalHistoryItem
                        key={index}
                        source={item}
                        onClick={() => {
                          console.log('mediaId', item.data.mediaId)
                          history.push(`/detail/${item.data.mediaId}`)
                        }}
                      ></HorizontalHistoryItem>
                    </S.ListItem>
                  )
                })}
            </S.StyledScroll>
            {curHistoryIndex !== historys.length - 1 &&
              historyScroll.current &&
              historyScroll.current.scrollWidth > historyScroll.current.clientWidth && (
                <S.ArrowRightPanel>
                  <S.ArrowIcon onClick={handleHistoryNext} src={ArrowRight} alt={'arrow-right'}></S.ArrowIcon>
                </S.ArrowRightPanel>
              )}
          </S.ListPanel>
        )}
      </S.Content>
    </PageWrapper>
  )
}
