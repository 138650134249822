import { animated, useSpring } from '@react-spring/web'
import DefaultImage from 'assets/images/default-image.png'
import AudioWavePlayer from 'components/AudioWavePlayer'
import { BigNumberish } from 'ethers/lib/ethers'
import { formatEther } from 'ethers/lib/utils'
import { useFetchTokenUriDataCallback } from 'hooks/useFetchDaoData'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

const CardPanel = styled.div`
  position: relative;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  counter-increment: item-counter;
  cursor: pointer;
`

const CardImage = styled(animated.img)`
  padding: 0px;
  cursor: auto;
  max-width: 100%;
  width: 100%;
`

const CardMusic = styled.div`
  padding: 0px;
  cursor: pointer;
  width: 100%;
`

const ForSale = styled.div`
  background: ${({ theme }) => theme.text3};
  border-radius: 0.72vh;
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  text-transform: uppercase;
  padding: 0.36vh 0.52vw;

  color: ${({ theme }) => theme.text6};
  z-index: 1;
  position: absolute;
  top: 1.16vh;
  right: 0.93vw;
`

const TextWrapper = styled(animated.div)`
  position: absolute;
  bottom: 2.51vh;
  left: 1.62vw;
  padding-top: 0px;
  max-width: 100%;
`

const TextPanel = styled.div`
  display: block;
`

const TextName = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 2.15vh;

  text-transform: uppercase;

  color: ${({ theme }) => theme.text2};
  white-space: normal;
  word-break: break-all;
`

const TextPrice = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  text-transform: uppercase;

  color: ${({ theme }) => theme.text2};
  text-align: left;
`

export default function NFTItem({
  image,
  forSale = false,
  price,
  onClick
}: {
  image: string
  forSale?: boolean
  price?: BigNumberish
  onClick?: () => void
}) {
  const [isHovering, setIsHovering] = useState(false)
  const Img = useRef<HTMLImageElement>()
  const { scale, opacity } = useSpring({
    from: { scale: 1, opacity: 1 },
    scale: isHovering ? 0.99 : 1,
    opacity: isHovering ? 0.12 : 1,
    config: { duration: 200 }
  })

  const { opacity: titleOpacity } = useSpring({
    from: { opacity: 1 },
    opacity: isHovering ? 1 : 0,
    config: { duration: 200 }
  })

  const fetchData = useFetchTokenUriDataCallback()
  const [data, setData] = useState(null as any)
  useEffect(() => {
    fetchData(image)
      .then((data) => {
        setData(data)
      })
      .catch((error) => console.debug('fetching data error', error))
  }, [fetchData, image])

  return (
    <CardPanel
      onMouseEnter={() => {
        setIsHovering(true)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
      }}
      onClick={onClick}
    >
      {!forSale && <ForSale>for sale</ForSale>}
      {data?.type === 'image' && (
        <>
          <CardImage
            ref={Img as any}
            style={{ scale, opacity }}
            src={data?.url ?? data?.image ?? DefaultImage}
            loading="lazy"
            alt=""
            onError={() => {
              if (Img.current) {
                Img.current.src = DefaultImage
              }
            }}
          />
          <TextWrapper
            style={{
              opacity: titleOpacity
            }}
          >
            <TextPanel>
              <TextName>{data?.name}</TextName>
              {price && <TextPrice>{formatEther(price)} ETH</TextPrice>}
            </TextPanel>
          </TextWrapper>
        </>
      )}
      {data?.type === 'audio' && (
        <CardMusic>
          <AudioWavePlayer playSize={'5.1vh'} source={data?.url} />
        </CardMusic>
      )}
    </CardPanel>
  )
}
