import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useTheme from 'hooks/useTheme'
import { useCallback, useEffect, useRef, useState } from 'react'
import { MoreVertical, Pause, Play, Volume2, VolumeX } from 'react-feather'
import styled from 'styled-components/macro'
// import { ExternalLink } from 'theme'

const Player = styled.div`
  position: relative;
  height: fit-content;
  display: flex;
  align-items: center;
  background: rgba(217, 217, 217, 0.22);
  border-radius: 113px;
  width: 100%;
  height: fit-content;
  padding: 1.97vh 2.08vw;
  svg {
    cursor: pointer;
  }
`

const TimeLabel = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  text-transform: uppercase;
  margin-left: 1.39vw;

  color: ${({ theme }) => theme.text2};
`

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 9,
  borderRadius: 8,
  width: '100%',
  marginLeft: '0.81vw',
  marginRight: '1.79vw',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#D9D9D9',
    border: 'none'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: '#00FF0A'
  }
}))

const FlyoutMenu = styled.div`
  position: absolute;
  top: 7.98vh;
  right: 2.03vw;
  width: fit-content;
  z-index: 1;

  text-align: center;
  cursor: pointer;

  // a {
  color: ${({ theme }) => theme.text2};
  border: 1px solid #fafafa;
  border-radius: 0.72vh;
  font-style: normal;
  font-weight: 400;
  font-size: 1.07vh;
  padding: 0.18vh 1.04vw;
  text-decoration: none;
  // }
`

export default function AudioLinearPlayer({ source }: { source: string }) {
  const theme = useTheme()
  const audioRef = useRef(null as any)
  const requestRef = useRef(null as any)
  const [playPercent, setPlayPercent] = useState(0)
  const [play, setPlay] = useState(false)
  const [muted, setMuted] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [durationTime, setDurationTime] = useState(0)
  const [showMore, setShowMore] = useState(false)
  const [curSource, setCurSource] = useState('')

  const handleDownload = useCallback(() => {
    fetch(source, { credentials: 'omit' })
      .then((response) => {
        console.log(response)
        if (response.ok) {
          response.blob().then(function (myBlob) {
            const elink = document.createElement('a')
            elink.download = source.substring(source.lastIndexOf('/') + 1)
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(myBlob)
            document.body.appendChild(elink)
            elink.click()
            document.body.removeChild(elink)
            setShowMore(false)
          })
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }, [source])

  const node = useRef<HTMLDivElement>(null)
  useOnClickOutside(
    node,
    showMore
      ? () => {
          setShowMore(false)
        }
      : undefined
  )

  useEffect(() => {
    if (source) {
      setPlayPercent(0)
      setPlay(false)
      setMuted(false)
      setCurrentTime(0)
      setDurationTime(0)
      setShowMore(false)
      setCurSource(source)
      if (audioRef.current) {
        audioRef?.current.pause()
        cancelAnimationFrame(requestRef.current)
        audioRef.current.muted = false
        audioRef.current.load()
      }
    }
  }, [source])

  const handleMuted = useCallback(() => {
    if (muted) {
      audioRef.current.muted = false
      setMuted(false)
    } else {
      audioRef.current.muted = true
      setMuted(true)
    }
  }, [muted])

  const calculateTime = useCallback((secs: any) => {
    const minutes = Math.floor(secs / 60)
    const seconds = Math.floor(secs % 60)
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    return `${minutes}:${returnedSeconds}`
  }, [])

  const whilePlaying = useCallback(() => {
    if (audioRef.current) {
      setCurrentTime(Math.floor(audioRef.current.currentTime))
      setPlayPercent(Math.floor(audioRef.current.currentTime) / Math.floor(durationTime))
      requestRef.current = requestAnimationFrame(whilePlaying)
    }
  }, [durationTime])

  const handlePlay = useCallback(() => {
    if (play) {
      setPlay(false)
      if (audioRef.current) {
        audioRef?.current.pause()
        cancelAnimationFrame(requestRef.current)
      }
    } else {
      setPlay(true)
      if (audioRef.current) {
        audioRef?.current.play()
        requestRef.current = requestAnimationFrame(whilePlaying)
      }
    }
  }, [play, whilePlaying])

  const onEnded = useCallback(() => {
    setPlay(false)
    setCurrentTime(0)
  }, [])

  const onLoadedMetaData = useCallback(() => {
    setDurationTime(audioRef.current.duration)
  }, [])

  useEffect(() => {
    let observerRefValue = null as any
    if (audioRef?.current) {
      observerRefValue = audioRef?.current
      if (audioRef?.current.readyState > 0) {
        setDurationTime(audioRef.current.duration)
      } else {
        audioRef?.current.addEventListener('loadedmetadata', onLoadedMetaData)
      }
      audioRef.current.addEventListener('ended', onEnded)
    }
    return () => {
      if (observerRefValue) {
        observerRefValue.pause()
        cancelAnimationFrame(requestRef.current)
        observerRefValue.removeEventListener('loadedmetadata', onLoadedMetaData)
        observerRefValue.removeEventListener('ended', onEnded)
      }
    }
  }, [onEnded, onLoadedMetaData])

  const handleMore = useCallback(() => {
    setShowMore(!showMore)
  }, [showMore])

  return (
    <Player>
      <audio crossOrigin={'*'} src={curSource} ref={audioRef}></audio>

      {play ? (
        <Pause onClick={handlePlay} fill={theme.text2} stroke={theme.text2}></Pause>
      ) : (
        <Play onClick={handlePlay} fill={theme.text2} stroke={theme.text2}></Play>
      )}
      <TimeLabel>
        {calculateTime(currentTime)}/{calculateTime(durationTime)}
      </TimeLabel>
      <BorderLinearProgress variant="determinate" value={playPercent * 100}></BorderLinearProgress>
      {muted ? (
        <VolumeX onClick={handleMuted} stroke={theme.text2}></VolumeX>
      ) : (
        <Volume2 onClick={handleMuted} stroke={theme.text2}></Volume2>
      )}
      <MoreVertical onClick={handleMore} style={{ marginLeft: '1.97vw' }} stroke={theme.text2}></MoreVertical>
      {showMore && (
        <FlyoutMenu ref={node} onClick={handleDownload}>
          Download
        </FlyoutMenu>
      )}
    </Player>
  )
}
