import socionc1 from 'assets/images/home-socicon1_1home-socicon1.png'
import socionc2 from 'assets/images/home-socicon2_1home-socicon2.png'
import socicon3 from 'assets/images/home-socicon3_1home-socicon3.png'
import socionc4 from 'assets/images/Icon-awesome-discord.png'
import styled from 'styled-components/macro'
import { ExternalLink } from 'theme'

const FooterPanel = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 3.7vw;
  position: relative;
  padding: 2.95vh 0px 4.21vh 0px;
  border-top: 1px solid ${({ theme }) => theme.text2};
`

const Copyright = styled.div`
  color: ${({ theme }) => theme.text2};
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  line-height: 1.88vh;
  text-transform: uppercase;
`

const Socials = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const SocilaIcon = styled(ExternalLink)`
  margin-left: 0.81vw;
  cursor: pointer;
  img {
    width: 1.97vw;
  }
`

export default function Footer() {
  return (
    <FooterPanel>
      <Copyright>Copyright © 2023 AW3C. All rights reserved.</Copyright>
      <Socials>
        <SocilaIcon href="https://twitter.com/AW3Cxyz">
          <img src={socionc1} alt="AW3C Twitter" />
        </SocilaIcon>
        <SocilaIcon href="https://discord.gg/aw3c">
          <img src={socionc4} alt="AW3C Discord" />
        </SocilaIcon>
        <SocilaIcon href="https://www.instagram.com/aw3c.xyz">
          <img src={socionc2} alt="AW3C Instagram" />
        </SocilaIcon>
        <SocilaIcon href="https://www.youtube.com/channel/UCcuVuZDbNdXhWI2Bf1oWi5A">
          <img src={socicon3} alt="AW3C Youtube" />
        </SocilaIcon>
      </Socials>
    </FooterPanel>
  )
}
