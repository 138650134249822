import { useSpring } from '@react-spring/web'
import About1 from 'assets/images/about1.png'
import About2 from 'assets/images/about2.png'
import About3 from 'assets/images/about3.png'
import About4 from 'assets/images/about4.png'
import About5 from 'assets/images/about5.png'
import About6 from 'assets/images/about6.png'
import About7 from 'assets/images/about7.png'
import About8 from 'assets/images/about8.png'
import * as S from 'pages/About/styles'
import { PageWrapper } from 'theme'

export default function About() {
  const bounceInterp = (r: any) => {
    return `translate3d(${100 * Math.sin(r + (2 * Math.PI) / 1.6)}px, 0, 0)`
  }
  const imgSpring1 = useSpring({
    from: { scale: 1, radians: 0 },
    to: [
      { scale: 1.2, radians: Math.PI },
      { scale: 1, radians: 0 }
    ],
    config: { duration: 3000 },
    loop: true,
    reset: true
  })
  const imgSpring2 = useSpring({
    from: { scale: 1.2, radians: -(2 * Math.PI) },
    to: [
      { scale: 1, radians: -Math.PI },
      { scale: 1.2, radians: -(2 * Math.PI) }
    ],
    config: { duration: 3500 },
    loop: true,
    reset: true
  })
  const imgSpring3 = useSpring({
    from: { scale: 0.8, radians: Math.PI },
    to: [
      { scale: 1, radians: 2 * Math.PI },
      { scale: 0.8, radians: Math.PI }
    ],
    config: { duration: 4000 },
    loop: true,
    reset: true
  })
  const imgSpring4 = useSpring({
    from: { scale: 1, radians: 0 },
    to: [
      { scale: 1.2, radians: 2 * Math.PI },
      { scale: 1, radians: 0 }
    ],
    config: { duration: 5000 },
    loop: true,
    reset: true
  })
  return (
    <PageWrapper>
      <S.SolagaPanel>
        <S.SologaImages>
          <S.ImagesPanel
            style={{
              marginTop: '6.89vh'
            }}
          >
            <S.SologaImageContainer style={{ width: '12.62vw' }}>
              <S.SologaImage
                style={{ scale: imgSpring1.scale, transform: imgSpring1.radians.interpolate(bounceInterp) }}
                src={About1}
                alt={'about1'}
              ></S.SologaImage>
            </S.SologaImageContainer>
            <S.SologaImageContainer style={{ width: '11.4vw', marginLeft: '8.68vw' }}>
              <S.SologaImage
                style={{ scale: imgSpring2.scale, transform: imgSpring2.radians.interpolate(bounceInterp) }}
                src={About2}
                alt={'about2'}
              ></S.SologaImage>
            </S.SologaImageContainer>
            <S.SologaImageContainer style={{ width: '10.3vw', marginLeft: '7.64vw' }}>
              <S.SologaImage
                style={{ scale: imgSpring3.scale, transform: imgSpring3.radians.interpolate(bounceInterp) }}
                src={About3}
                alt={'about3'}
              ></S.SologaImage>
            </S.SologaImageContainer>
            <S.SologaImageContainer style={{ width: '17.25vw', marginLeft: '14.7vw' }}>
              <S.SologaImage
                style={{ scale: imgSpring4.scale, transform: imgSpring4.radians.interpolate(bounceInterp) }}
                src={About4}
                alt={'about4'}
              ></S.SologaImage>
            </S.SologaImageContainer>
          </S.ImagesPanel>
          <S.ImagesPanel
            style={{
              marginTop: '14.41vh',
              alignItems: 'flex-end'
            }}
          >
            <S.SologaImageContainer style={{ width: '10.53vw' }}>
              <S.SologaImage
                style={{ scale: imgSpring4.scale, transform: imgSpring4.radians.interpolate(bounceInterp) }}
                src={About5}
                alt={'about5'}
              ></S.SologaImage>
            </S.SologaImageContainer>
            <S.SologaImageContainer style={{ width: '9.95vw', marginLeft: '11.46vw' }}>
              <S.SologaImage
                style={{ scale: imgSpring3.scale, transform: imgSpring3.radians.interpolate(bounceInterp) }}
                src={About6}
                alt={'about6'}
              ></S.SologaImage>
            </S.SologaImageContainer>
            <S.SologaImageContainer style={{ width: '11vw', marginLeft: '12.44vw' }}>
              <S.SologaImage
                style={{ scale: imgSpring2.scale, transform: imgSpring2.radians.interpolate(bounceInterp) }}
                src={About7}
                alt={'about7'}
              ></S.SologaImage>
            </S.SologaImageContainer>
            <S.SologaImageContainer style={{ width: '21.01vw', marginLeft: '10.71vw' }}>
              <S.SologaImage
                style={{ scale: imgSpring1.scale, transform: imgSpring1.radians.interpolate(bounceInterp) }}
                src={About8}
                alt={'about8'}
              ></S.SologaImage>
            </S.SologaImageContainer>
          </S.ImagesPanel>
        </S.SologaImages>
        <S.SologaContainer>
          <S.Sologa>
            AW3C is leading ‘the renaissance of art’ to a generational change in art perception, consumption and
            distribution.
          </S.Sologa>
        </S.SologaContainer>
      </S.SolagaPanel>
      <S.FaqPanel>
        <S.FaqTitle>FAQ</S.FaqTitle>
        <S.FaqSubTitle>AW3C’s vison</S.FaqSubTitle>
        <S.FaqContent>
          Our vision at AW3C is to revolutionize the way creative content is produced and shared, by harnessing the
          power of AI and the security of blockchain technology. We strive to provide a platform for artists, creators
          and brands to easily produce, store and distribute their work immutably and globally, fostering new forms of
          creativity and engagement. We believe that AI-generated art will play a major role in the future of the art
          world, and we&apos;re excited to be at the forefront of this emerging trend.
        </S.FaqContent>
        <S.FaqSubTitle>AW3C Platform</S.FaqSubTitle>
        <S.FaqContent>
          AW3C is building the first-of-its-kind platform focused on AI-generated content that is stored immutably on
          the Ethereum Blockchain. It represents fundamental innovation in the combination of creativity and technology.
        </S.FaqContent>
        <S.FaqSubTitle>About AW3C</S.FaqSubTitle>
        <S.FaqContent>
          The advancement of AI-generated content, such as images, videos, 3D, music, and even movies, is progressing at
          an insane speed.
          <br /> This AI technology is so profound that it enables anyone without any technical skills to create art,
          design, music, video, and even film with just words in seconds.
          <br /> With this technology becoming more and more mainstream, we will see an explosion in creative
          expression; there will be more art, designs, music, videos, and all forms of creative content made in the next
          few years than in the entire human history.
          <br /> As AI technology continues to evolve, new opportunities await in this exciting space. We are seeing
          more and more applications built on AI.
          <br />
          <br /> AW3C focuses on the combination of AI and Blockchain because we believe that Blockchain technology and
          NFT is the best way to protect the copyright and proof of authenticity of AI-generated digital content. This
          allows for the buying and selling of these contents at any time, anywhere around the world without the trouble
          of logistics, intermediary fees, and insurance. Delivery is almost instant and the transaction is secured and
          transparent using smart contract.
          <br />
          <br /> AW3C facilitates almost everyone to be able to use AI tools to create, store and distribute
          Ai-generative content in a very efficient way by utilizing blockchain technology. This allows for a more
          democratic and decentralized approach to content creation and distribution.
        </S.FaqContent>
        <S.FaqSubTitle>Why Blockchain</S.FaqSubTitle>
        <S.FaqContent>
          For centuries, artists and collectors have struggled with various issues such as authenticity, logistics,
          storage, theft, high intermediary fees, and poor liquidity. AW3C uses blockchain technology to create AI
          generated art as NFTs (non-fungible tokens) stored immutably on the blockchain. The authenticity of the art is
          guaranteed. Collectors are able to buy and sell art anytime, anywhere around the world without the trouble of
          logistics, intermediary fees, and insurance. Delivery is almost instant.
        </S.FaqContent>
        <S.FaqSubTitle>Why NFT</S.FaqSubTitle>
        <S.FaqContent>
          In the past, there were major issues that prevented the golden age of gen-art. They were technical, aesthetic,
          and financial. Today, all those limitations have disappeared, and it is now possible to create, store, and
          distribute generative art in a very efficient way by utilizing blockchain technology. It is the best way to
          value artwork and give credit to an artist.
          <br />
          AW3C uses ERC721 standard to tokenise generative art, and enable it to be stored, transferred and traded on
          the blockchain.
        </S.FaqContent>
        <S.FaqSubTitle>Are there platform fees?</S.FaqSubTitle>
        <S.FaqContent>
          The AW3C platform takes 10% of the primary sales and 2.5% of all secondary sales. Balance of each sale will be
          deposited to the artist/creator immediately.
        </S.FaqContent>
        <S.FaqSubTitle>How does creator secondary royalty works?</S.FaqSubTitle>
        <S.FaqContent>
          For all secondary sales on the market, creator always receive 5% of the purchase price.
        </S.FaqContent>
        <S.FaqSubTitle>How does the payout work?</S.FaqSubTitle>
        <S.FaqContent>
          At each sale (primary and secondary) of your collection, your creator&apos;s percentage payment will be
          transferred immediately to your wallet address that you use to submit your artwork. All payouts will be in the
          form of ETH.
        </S.FaqContent>
        <S.FaqSubTitle>How does copyright works?</S.FaqSubTitle>
        <S.FaqContent>
          Currently, both U.S and U.K officials have rejected copyright protection for Artificial Intelligence generated
          images, claiming &apos;image generated through artificial intelligence lacked the &quot;human authorship&quot;
          necessary for protection&apos;. But in other countries like Australia, South Africa, inventors of AI generated
          work can apply for a patent. Nevertheless, the best way to protect your artwork is through blockchain
          technology where the ownership record is stored immutably on the blockchain and can be verified by the public.
        </S.FaqContent>
        <S.FaqSubTitle>What is a project license, and which rights does it grant to me?</S.FaqSubTitle>
        <S.FaqContent>
          Currently, all projects on the AW3C platform are covered under the{' '}
          <S.StyledExtLink href="https://www.niftylicense.org">NFT License 2.0</S.StyledExtLink>. Purchasing artwork on
          AW3C does not give collectors intellectual property rights to the underlying AI algorithm that generates the
          content. If you have any questions about the appropriate use of an art piece, please contact the
          artist/creator directly for permission or clarification.
        </S.FaqContent>
        <S.FaqSubTitle>How do I get a wallet?</S.FaqSubTitle>
        <S.FaqContent>
          You will need a wallet to sell artwork and receive funds.
          <br /> There are several options to choose from{' '}
          <S.StyledExtLink href="https://ethereum.org/en/wallets/">https://ethereum.org/en/wallets</S.StyledExtLink>
        </S.FaqContent>
        <S.FaqSubTitle>How do I purchase/mint an artwork NFT?</S.FaqSubTitle>
        <S.FaqContent>
          Connect your Ethereum wallet and choose an art work you like. Simply click ‘Mint’, and follow the steps of
          your wallet to confirm the transaction, then it will be minted as an ERC721 token on the Ethereum blockchain.
        </S.FaqContent>
        <S.FaqSubTitle>What if my questions isn’t answered here?</S.FaqSubTitle>
        <S.FaqContent>
          If you can&apos;t find the answer here, please join our Discord server. In Discord, you can find our thriving
          community of talented artists and collectors. You can also join daily conversations about AI generated art and
          upcoming projects. Additionally, Discord is the best place to ask us for help. You will find information about
          our artist application process, user feedback & announcements for all things AW3C. We&apos;d love to have you
          there!
        </S.FaqContent>
        <S.FaqContent>
          Click <S.StyledExtLink href="https://discord.gg/aw3c">here</S.StyledExtLink> to join!
        </S.FaqContent>
      </S.FaqPanel>
    </PageWrapper>
  )
}
