// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import StatusIcon from 'components/Identicon/StatusIcon'
import Loader from 'components/Loader'
import { RowBetween } from 'components/Row'
import WalletModal from 'components/WalletModal'
import { coinbaseWallet, getWalletForConnector } from 'connectors'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useMemo, useRef } from 'react'
import { Activity } from 'react-feather'
import { useModalOpen, useToggleModal, useWalletModalToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/types'
import { updateSelectedWallet, updateUserInfo } from 'state/user/reducer'
import styled from 'styled-components/macro'
import { shortenAddress } from 'utils'
import { isChainAllowed } from 'utils/switchChain'

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

const Web3StatusGeneric = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  cursor: pointer;
  user-select: none;
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>``

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vh;
  line-height: 1.7vh;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function WrappedStatusIcon({ connector }: { connector: Connector }) {
  return (
    <IconWrapper size={16}>
      <StatusIcon connector={connector} />
    </IconWrapper>
  )
}
const FlyoutHeader = styled.div`
  color: #e7e7e7;
  font-weight: 400;
  cursor: pointer;
  width: 100%;
`
const FlyoutMenu = styled.div`
  position: absolute;
  top: 8.42vh;
  right: 3.3vw;
  width: fit-content;
  z-index: 99;
`
const FlyoutMenuContents = styled.div`
  align-items: flex-start;

  border: 1px solid ${({ theme }) => theme.text2};
  border-radius: 0.72vh;
  background-color: ${({ theme }) => theme.bg0};
  display: flex;
  flex-direction: column;
  font-size: 1.43vh;
  padding: 0.36vh 1.5vw;
  overflow: auto;
`

function Web3StatusInner() {
  const { account, connector, chainId, ENSName } = useWeb3React()

  const error = useAppSelector((state) => state.wallet.errorByWallet[getWalletForConnector(connector)])

  const chainAllowed = chainId && isChainAllowed(connector, chainId)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()

  const dispatch = useAppDispatch()
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.PROFILE_MENU)
  const toggle = useToggleModal(ApplicationModal.PROFILE_MENU)
  useOnClickOutside(node, open ? toggle : undefined)

  if (!chainId) {
    return <div onClick={toggleWalletModal}>My Wallet</div>
  } else if (!chainAllowed) {
    return (
      <div style={{ display: 'flex' }} onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>
          <Trans>Wrong Network</Trans>
        </Text>
      </div>
    )
  } else if (error) {
    return (
      <div style={{ display: 'flex' }} onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>
          <Trans>Error</Trans>
        </Text>
      </div>
    )
  } else if (account) {
    return (
      <Web3StatusConnected
        onClick={() => {
          // if (open) toggle()
          // toggleWalletModal()
          toggle()
        }}
        pending={hasPendingTransactions}
        ref={node as any}
        // onMouseEnter={toggle}
        // onMouseLeave={() => {
        //   console.log('onMouseLeave')
        //   if (open) toggle()
        // }}
      >
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>{pending?.length} Pending</Text> <Loader stroke="white" />
          </RowBetween>
        ) : (
          <Text>{ENSName || shortenAddress(account)}</Text>
        )}
        {!hasPendingTransactions && connector && <WrappedStatusIcon connector={connector} />}
        {open && (
          <FlyoutMenu>
            <FlyoutMenuContents>
              <FlyoutHeader
                onClick={(event) => {
                  if (open) toggle()
                  event.stopPropagation()
                  if (connector.deactivate) {
                    connector.deactivate()

                    // Coinbase Wallet SDK does not emit a disconnect event to the provider,
                    // which is what web3-react uses to reset state. As a workaround we manually
                    // reset state.
                    if (connector === coinbaseWallet) {
                      connector.resetState()
                    }
                  } else {
                    connector.resetState()
                  }

                  dispatch(updateSelectedWallet({ wallet: undefined }))
                  dispatch(updateUserInfo({ userInfo: null }))
                }}
              >
                <Trans>Logout</Trans>
              </FlyoutHeader>
            </FlyoutMenuContents>
          </FlyoutMenu>
        )}
      </Web3StatusConnected>
    )
  } else {
    return <div onClick={toggleWalletModal}>Connect Wallet</div>
  }
}

export default function Web3Status() {
  const { ENSName } = useWeb3React()

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

  return (
    <>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
