import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { SupportedChainId } from 'constants/chains'
import ms from 'ms.macro'
import store from 'state'
import { ResponseBackend } from 'state/daos/types'
import { jsonToHump } from 'state/daos/utils'

const CHAIN_API_URL: Record<number, string> = {
  [SupportedChainId.MAINNET]: 'https://test.blockchainhardwork.xyz'
  // [SupportedChainId.RINKEBY]: 'https://api.test.aw3c.xyz',
  // [SupportedChainId.LOCAL_HOST]: 'https://api.test.aw3c.xyz'
}

function getBaseUri(): any {
  const chainId = store?.getState()?.application?.chainId
    ? store?.getState()?.application?.chainId
    : SupportedChainId.MAINNET

  const apiUrl = chainId ? CHAIN_API_URL[chainId] : 'https://test.blockchainhardwork.xyz'
  return apiUrl
}

export const daoApi = createApi({
  reducerPath: 'daoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUri(),
    prepareHeaders: (headers) => {
      const { user } = store?.getState()
      if (user && user.userInfo && user.userInfo.accessToken) {
        headers.set('Authorization', user.userInfo.accessToken)
      }
      return headers
    }
  }),
  endpoints: (build) => ({
    loginBySigned: build.mutation<
      ResponseBackend,
      {
        address: string
        message: string
        signature: string
      }
    >({
      query: (body: any) => ({
        url: `/user/v1/login/ethereum`,
        method: 'POST',
        body
      })
    }),
    uploadFile: build.mutation<any, any>({
      query: (body: { url: string; body: any }) => ({
        url: body.url,
        method: 'PUT',
        body: body.body
      })
    }),
    submitArtwork: build.mutation<any, any>({
      query: (body: any) => ({
        url: `nft/v1/project/submit`,
        method: 'POST',
        body
      })
    }),
    getProjects: build.query<any[], { address: string }>({
      async queryFn(args, _api, _extraOptions, fetch) {
        let result

        try {
          result = await fetch(`/nft/v1/projects`)
          const response = result.data as ResponseBackend
          jsonToHump(response.data)

          return { data: response.data as any[] }
        } catch (e) {
          return { error: e as FetchBaseQueryError }
        }
      },
      keepUnusedDataFor: ms`10s`,
      extraOptions: {
        maxRetries: 0
      }
    }),
    getPresign: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/nft/v1/project/presign`,
        method: 'GET',
        params
      })
    })
  })
})

export const {
  useLoginBySignedMutation,
  useUploadFileMutation,
  useSubmitArtworkMutation,
  useGetProjectsQuery,
  useGetPresignMutation
} = daoApi
