import { useWeb3React } from '@web3-react/core'
import Web3Status from 'components/Web3Status'
import { SupportedChainId } from 'constants/chains'
// import { GOERLI_PROVIDER } from 'constants/infura'
// import { INFURA_NETWORK_URLS } from 'constants/infura'
import { PublicUrl } from 'constants/publicurl'
import { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
// import Web3 from 'web3'

const HeaderPanel = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  display: flex;
  padding: 4.48vh 0px 1.24vh 0px;
  z-index: 3;
  background-color: ${({ theme }) => theme.bg0};
`

const MenuPanel = styled.nav`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: ${({ theme }) => theme.text2};
  margin-right: 3.3vw;
  margin-bottom: 1.07vh;
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 5.79vw;
`

const MenuItem = styled.div<{ active?: boolean; disabled?: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vh;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  color: ${({ disabled, theme }) => (disabled ? '#1e1e1e' : theme.text2)};

  :hover {
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

    text-decoration: underline;
  }
`

const MenuLogo = styled.div`
  margin-left: 3.7vw;
  cursor: pointer;
  img {
    height: 7.16vh;
  }
`

export default function Header() {
  const history = useHistory()
  const location = useLocation()
  const { chainId, account } = useWeb3React()
  const active = useMemo(() => {
    return location.pathname
  }, [location.pathname])

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <HeaderPanel>
      <MenuLogo
        onClick={() => {
          history.push('/')
        }}
      >
        <img src={PublicUrl(chainId ?? SupportedChainId.MAINNET) + '/images/Logo.png'} alt="AW3C" />
      </MenuLogo>
      <MenuPanel>
        <Menu>
          <MenuItem
            onClick={() => {
              history.push('/')
            }}
            active={active === '/'}
          >
            Home
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push('/about')
            }}
            active={active === '/about'}
          >
            About AW3C
          </MenuItem>
        </Menu>

        <Menu>
          <MenuItem
            onClick={() => {
              history.push('/create', { mediaId: '' })
            }}
            active={active === '/create'}
          >
            Create AI Arts
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push('/collection')
            }}
            active={active === '/collection'}
          >
            NFT Marketplace
          </MenuItem>
        </Menu>

        <Menu>
          <MenuItem>
            <Web3Status></Web3Status>
          </MenuItem>
          {/* <MenuItem
            onClick={() => {
              history.push('/submit')
            }}
          >
            Submit Artwork
          </MenuItem> */}
          <MenuItem
            onClick={() => {
              if (!account) return
              history.push('/myartwork')
            }}
            disabled={!account}
            active={active === '/myartwork'}
          >
            My Artwork
          </MenuItem>
        </Menu>
      </MenuPanel>
      <div
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
      >
        <div className="icon w-icon-nav-menu"></div>
      </div>
    </HeaderPanel>
  )
}
