import styled from 'styled-components/macro'
import { ExternalLink } from 'theme/components'

export const Content = styled.div`
  padding: 7.88vh 2.43vw 9.88vh 3.7vw;
  display: flex;
`

export const ImagePanel = styled.div`
  width: 53.7vw;
  img {
    width: 100%;
    object-fit: cover;
  }
`

export const AudioPanel = styled.div`
  width: 53.7vw;
`

export const DetailPanel = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  flex: 1;

  margin-left: 2.55vw;
  color: ${({ theme }) => theme.text1};

  a {
    color: ${({ theme }) => theme.text1};
  }
`

export const NFTName = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 3.58vh;
  line-height: 4.74vh;

  text-transform: uppercase;

  color: ${({ theme }) => theme.text2};
`

export const Creator = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  // text-decoration-line: underline;
  line-height: 1.88vh;

  color: ${({ theme }) => theme.text2};
`

export const BackButton = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.79vh;
  margin-top: 6.62vh;

  text-decoration-line: underline;
  cursor: pointer;

  color: ${({ theme }) => theme.text2};
`

export const Time = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  line-height: 1.88vh;
  text-transform: uppercase;
  display: flex;
  position: relative;
  width: fit-content;
  margin-top: 0.27vh;
`

export const GMTTime = styled.div`
  height: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 0.72vh;
  line-height: 1.34vh;
  margin-left: 0.29vw;
  text-transform: uppercase;
  display: flex;
  flex: column;
  align-items: flex-start;
  width: fit-content;
`

export const StyledExtLink = styled(ExternalLink)`
  text-decoration: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  line-height: 1.88vh;

  color: ${({ theme }) => theme.text1};
  text-decoration: underline;
`

export const Download = styled.div`
  margin-top: 2.42vh;

  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  line-height: 1.88vh;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.text2};
  cursor: pointer;
  a {
    font-style: normal;
    font-weight: 300;
    font-size: 1.43vh;
    line-height: 1.88vh;
    text-decoration-line: underline;
    color: ${({ theme }) => theme.text2};
  }
`

export const SellPanel = styled.div<{ disabled: boolean }>`
  display: flex;
  margin-top: 17.73vh;
  opacity: ${({ disabled }) => (disabled ? '0.22' : '1')};
`

export const SellInput = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 1px solid #dad5d5;
  margin-right: 3.7vw;

  margin: 1.7vh 3.7vw 1.7vh 0;
`

export const PriceInput = styled.input`
  flex: 1;
  font-size: 2.15vh;
  outline: none;
  border: none;
  flex: 1 1 auto;
  width: 0;
  background-color: ${({ theme }) => theme.bg0};
  color: ${({ theme }) => theme.text1};
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
`

export const SymbolLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 2.15vh;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.text1};
`

export const SellButton = styled.div`
  border: 1px solid ${({ theme }) => theme.text2};
  border-radius: 0.72vh;
  font-style: normal;
  font-weight: 300;
  font-size: 3.22vh;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  padding: 1.79vh 4.05vw;

  color: ${({ theme }) => theme.text2};
`

export const SellSuccessPanel = styled.div`
  margin-top: 5.37vh;
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;

  color: ${({ theme }) => theme.text1};
`

export const Congratulation = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 4.3vh;
  margin-bottom: 0.54vh;

  color: ${({ theme }) => theme.text3};
`

export const Price = styled.div`
  margin-bottom: 1.88vh;
  margin-top: 0.54vh;
  font-style: normal;
  font-weight: 400;
  font-size: 2.86vh;
  text-transform: uppercase;

  color: ${({ theme }) => theme.text1};
`

export const BuyPanel = styled.div`
  margin-top: 2.42vh;
`

export const BuyPrice = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 2.86vh;
  text-transform: uppercase;

  color: ${({ theme }) => theme.text1};
`

export const BuyButton = styled.div<{ disabled: boolean }>`
  margin-top: 2.42vh;
  border: 1px solid ${({ theme }) => theme.text2};
  border-radius: 0.72vh;
  font-style: normal;
  font-weight: 300;
  font-size: 3.22vh;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.22' : '1')};

  color: ${({ theme }) => theme.text2};
`
