import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { SupportedChainId } from 'constants/chains'
import store from 'state'
import { ResponseBackend } from 'state/ai/types'

const CHAIN_API_URL: Record<number, string> = {
  [SupportedChainId.MAINNET]: 'https://test.blockchainhardwork.xyz'
  // [SupportedChainId.RINKEBY]: 'https://api.test.aw3c.xyz',
  // [SupportedChainId.LOCAL_HOST]: 'https://api.test.aw3c.xyz'
}

function getBaseUri(): any {
  const chainId = store?.getState()?.application?.chainId
    ? store?.getState()?.application?.chainId
    : SupportedChainId.MAINNET

  const apiUrl = chainId ? CHAIN_API_URL[chainId] : 'https://test.blockchainhardwork.xyz'
  return apiUrl
}

export const aiApi = createApi({
  reducerPath: 'aiApi',
  baseQuery: fetchBaseQuery({
    baseUrl: getBaseUri(),
    prepareHeaders: (headers) => {
      const { user } = store?.getState()
      if (user && user.userInfo && user.userInfo.accessToken) {
        headers.set('Authorization', user.userInfo.accessToken)
      }
      return headers
    }
  }),
  endpoints: (build) => ({
    textToImage: build.mutation<
      ResponseBackend,
      {
        url?: string
        prompt?: string
        seed?: number
        cfgScale?: number
        width?: number
        height?: number
        negativePrompt?: string
      }
    >({
      query: (body: any) => ({
        url: `/v1/ai/generate/sd`,
        method: 'POST',
        body
      })
    }),
    openAI: build.mutation<
      ResponseBackend,
      {
        prompt: string
        n?: number
        size?: string
      }
    >({
      query: (body: any) => ({
        url: `/v1/ai/generate/openAI`,
        method: 'POST',
        body
      })
    }),
    generateMusic: build.mutation<
      ResponseBackend,
      {
        url: string
        prompt: string
        duration: number
        loop: boolean
        email: string
      }
    >({
      query: (body: any) => ({
        url: `/v1/ai/generate/mubert`,
        method: 'POST',
        body
      })
    }),
    getAIList: build.mutation<
      any,
      {
        beforeCursor?: string
        afterCursor?: string
        pageSize: number
        orderBy?: string
        resourceType?: string
        search?: string
      }
    >({
      query: (params: any) => ({
        url: `/v1/ai/resource/query`,
        method: 'GET',
        params
      })
    }),
    getNftsList: build.mutation<
      any,
      {
        beforeCursor?: string
        afterCursor?: string
        pageSize: number
        type?: string
        orderBy?: string
        status?: string
        userId?: string
      }
    >({
      query: (params: any) => ({
        url: `/v1/ai/nft/query`,
        method: 'GET',
        params
      })
    }),
    getOrdersList: build.mutation<
      any,
      {
        beforeCursor?: string
        afterCursor?: string
        pageSize: number
        orderBy?: string
        status?: string
        nftId?: string
      }
    >({
      query: (params: any) => ({
        url: `/v1/ai/order/query`,
        method: 'GET',
        params
      })
    }),
    getAiMediaDetail: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/ai/mediaDetail`,
        method: 'GET',
        params
      })
    }),
    createOrder: build.mutation<
      ResponseBackend,
      {
        tokenId: number
        contract: string
        orderType: string
        orderStatus: string
        context: any
      }
    >({
      query: (body: any) => ({
        url: `/v1/ai/createOrder`,
        method: 'POST',
        body
      })
    }),
    updateOrder: build.mutation<
      ResponseBackend,
      {
        orderId: string
        orderType: string
        orderStatus: string
        context: any
        tokenId: number
        contract: string
        buyer: string
      }
    >({
      query: (body: any) => ({
        url: `/v1/ai/updateOrder`,
        method: 'POST',
        body
      })
    }),
    mintFinish: build.mutation<any, any>({
      query: (params: any) => ({
        url: `/v1/ai/mintFinish`,
        method: 'GET',
        params
      })
    })
  })
})

export const {
  useGetAIListMutation,
  useGetAiMediaDetailMutation,
  useGenerateMusicMutation,
  useOpenAIMutation,
  useMintFinishMutation,
  useTextToImageMutation,
  useGetNftsListMutation,
  useGetOrdersListMutation,
  useCreateOrderMutation,
  useUpdateOrderMutation
} = aiApi
