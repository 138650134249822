import Marquee from 'react-fast-marquee'
import styled from 'styled-components/macro'

export const Content = styled.div``

export const MarqueenHeader = styled(Marquee)`
  font-style: normal;
  font-weight: 300;
  font-size: 8.59vh;

  color: ${({ theme }) => theme.text2};
`

export const ListPanel = styled.div`
  margin: 2.06vh 3.36vw 0px 3.7vw;
  padding-bottom: 12.98vh;
`
