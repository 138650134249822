import Marquee from 'react-fast-marquee'
import styled from 'styled-components/macro'

export const Content = styled.div`
  padding-bottom: 12.98vh;
`

export const MarqueenHeader = styled(Marquee)`
  font-style: normal;
  font-weight: 300;
  font-size: 8.59vh;

  color: ${({ theme }) => theme.text2};
`

export const ArtworkHeader = styled.div`
  display: flex;
  margin-left: 3.7vw;
  margin-right: 3.94vw;
  align-items: flex-end;
`

export const HeaderLabel = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 3.94vh;
  line-height: 5.19vh;

  color: ${({ theme }) => theme.text2};
`

export const ViewMore = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.79vh;
  line-height: 2.42vh;

  text-decoration-line: underline;

  color: ${({ theme }) => theme.text2};
  cursor: pointer;
`

export const ListPanel = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
`

export const ArrowLeftPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 3.18vw;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  mix-blend-mode: difference;
  background-blend-mode: difference;
`

export const ArrowRightPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 3.18vw;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  mix-blend-mode: difference;
  background-blend-mode: difference;
`
export const ArrowIcon = styled.img`
  cursor: pointer;
  height: 3.67vh;
`

export const StyledScroll = styled.div`
  padding-left: 3.7vw;
  margin-top: 3.22vh;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
  position: relative;
`

export const ListItem = styled.div`
  margin-right: 1.1vw;
  cursor: pointer;
  height: 39.39vh;
  width: fit-content;
`
