import { animated } from '@react-spring/web'
import Marquee from 'react-fast-marquee'
import styled from 'styled-components/macro'

export const StyledSwiper = styled.div`
  margin-top: 3.95vh;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 3.7vw;
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
  position: relative;
`

export const StyledSwiperSlider = styled.div`
  color: ${({ theme }) => theme.text2};
  margin-right: 2.72vw;
  cursor: pointer;
  height: 100%;
`

export const StyledMarquee = styled(Marquee)`
  font-style: normal;
  font-weight: 300;
  font-size: 8.59vh;
  color: ${({ theme }) => theme.text2};
`

export const ArrowLeftPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 3.18vw;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  mix-blend-mode: difference;
  background-blend-mode: difference;
`

export const ArrowRightPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 3.18vw;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  mix-blend-mode: difference;
  background-blend-mode: difference;
`
export const ArrowIcon = styled.img`
  cursor: pointer;
  height: 3.67vh;
`

export const Collection = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
`

export const SloganPanel = styled.div`
  width: 100%;
`

export const MenuPanel = styled.div`
  display: flex;
  margin: 1.34vh 3.18vw;
  margin-bottom: 17.46vh;

  margin-top: 3.76vh;
`

export const LogoPanel = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  img {
    height: 39.75vh;
  }
`

export const MenuContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${({ theme }) => theme.text2};
`

export const MenuItem = styled.div`
  cursor: default;
  display: flex;
  flex-direction: column;
`

export const MenuTitle = styled.div`
  line-height: 5px;
  cursor: pointer;
  margin-top: -3.76vh;
`

export const TitleSpan = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 23.28vh;
  line-height: 1.1;
  text-transform: uppercase;
  white-space: nowrap;
`

export const HoverSpan = styled(animated.span)`
  font-style: normal;
  font-weight: 700;
  font-size: 23.28vh;
  line-height: 1.1;
  text-transform: uppercase;
  white-space: nowrap;
`

export const MenuSubTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  line-height: 3.76vh;
  margin-top: -3.76vh;
  padding: 1.88vh 0 3.76vh 0;
  text-align: right;
  z-index: 1;
`
