import * as S from 'pages/Submit/styles'
import { PageWrapper } from 'theme'

export default function Submit() {
  return (
    <PageWrapper>
      <S.Content></S.Content>
    </PageWrapper>
  )
}
