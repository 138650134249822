import { animated } from '@react-spring/web'
import styled from 'styled-components/macro'
import { ExternalLink } from 'theme/components'

export const SolagaPanel = styled.div`
  margin-left: 4.11vw;
  margin-right: 3.65vw;
  position: relative;
`

export const SologaImages = styled.div``

export const ImagesPanel = styled.div`
  display: flex;
  width: 100%;
`

export const SologaImageContainer = styled(animated.div)``

export const SologaImage = styled(animated.img)`
  width: 100%;
`

export const SologaContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Sologa = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 7.88vh;
  z-index: 1;

  color: ${({ theme }) => theme.text1};
`

export const FaqPanel = styled.div`
  padding-bottom: 14.32vh;
`

export const FaqTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 23.28vh;
  text-transform: uppercase;
  margin-left: 2.66vw;

  color: ${({ theme }) => theme.text2};
`

export const FaqSubTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 3.94vh;
  line-height: 5.19vh;

  margin-top: 4.57vh;
  margin-left: 3.7vw;

  color: ${({ theme }) => theme.text2};
`

export const FaqContent = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 1.43vh;
  line-height: 1.88vh;

  margin-top: 1.79vh;
  margin-left: 3.7vw;

  max-width: 50%;

  color: ${({ theme }) => theme.text1};
`

export const StyledInLink = styled.em`
  text-decoration: none;
  cursor: pointer;
  text-decoration: underline;
`

export const StyledExtLink = styled(ExternalLink)`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  text-decoration: underline;
`
