export enum Status {
  init,
  start,
  matchOrders,
  transactionCreated,
  transactionConfirmed,
  transferred,
  error,
  complete
}
