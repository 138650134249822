import { useSpring } from '@react-spring/web'
import ArrowLeft from 'assets/images/icon-arrow-left.png'
import ArrowRight from 'assets/images/icon-arrow-right.png'
import Logo from 'assets/images/logo-home.gif'
import TokenImage from 'components/TokenImage'
import * as S from 'pages/Home/styles'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useGetNftsListMutation } from 'state/ai/slice'
import { useAddPopup } from 'state/application/hooks'
import { PageWrapper } from 'theme/components'

export default function Home() {
  const [topList, setTopList] = useState([] as any[])
  const [getAIList] = useGetNftsListMutation()
  const parrentScroll = useRef(null as any)

  const history = useHistory()
  const addPopup = useAddPopup()
  const [curIndex, setCurIndex] = useState(0)
  const [lastAction, setLastAction] = useState(0) // 0 initail 1 pre 2 next

  const [isHovering, setIsHovering] = useState(false)
  const [isSellHovering, setIsSellHovering] = useState(false)
  const [isExplorerHovering, setIsExplorerHovering] = useState(false)

  useEffect(() => {
    getAIList({ pageSize: 10, orderBy: 'DESC', type: 'all' })
      .then((data: any) => {
        console.log('ailist', data, data?.data?.data?.data.length)
        if (data?.data?.code === 0) {
          setTopList([].concat(data?.data?.data?.data))
        } else {
          addPopup({ error: 'Get AI list failed, please retry.' })
        }
      })
      .catch((e) => {
        console.error(e)
        addPopup({ error: 'Get AI list failed, please retry.' })
      })
  }, [addPopup, getAIList])

  const handleNext = useCallback(() => {
    console.log('handleNext')
    if (lastAction === 0 || lastAction === 1) {
      let steps = curIndex + 7
      if (steps >= topList.length - 1) {
        steps = topList.length - 1
        setCurIndex(steps)
        setLastAction(2)

        parrentScroll.current.scrollTo({
          left: parrentScroll.current.scrollWidth,
          behavior: 'smooth'
        })
        return
      }
      console.log('steps', steps)
      const element = document.getElementById(`section-${steps}`)
      setCurIndex(steps)
      if (element) {
        setLastAction(2)
        console.log('scrollIntoView', `section-${steps}`)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
    } else {
      let steps = curIndex + 4
      if (steps >= topList.length - 1) {
        steps = topList.length - 1
        setCurIndex(steps)
        setLastAction(2)

        parrentScroll.current.scrollTo({
          left: parrentScroll.current.scrollWidth,
          behavior: 'smooth'
        })
        return
      }
      console.log('steps', steps)
      const element = document.getElementById(`section-${steps}`)
      setCurIndex(steps)
      if (element) {
        setLastAction(2)
        console.log('scrollIntoView', `section-${steps}`)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
    }
  }, [curIndex, lastAction, topList.length])

  const handlePre = useCallback(() => {
    console.log('handlePre')
    if (lastAction === 0 || lastAction === 2) {
      let steps = curIndex - 7
      if (steps <= 0) {
        steps = 0
        setCurIndex(steps)
        setLastAction(1)
        parrentScroll.current.scrollTo({
          left: 0,
          behavior: 'smooth'
        })
        return
      }
      console.log('steps', steps)
      const element = document.getElementById(`section-${steps}`)
      setCurIndex(steps)
      if (element) {
        setLastAction(1)
        console.log('scrollIntoView', `section-${steps}`)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
    } else {
      let steps = curIndex - 4
      if (steps <= 0) {
        steps = 0
        setCurIndex(steps)
        setLastAction(1)
        parrentScroll.current.scrollTo({
          left: 0,
          behavior: 'smooth'
        })
        return
      }
      console.log('steps', steps)
      const element = document.getElementById(`section-${steps}`)
      setCurIndex(steps)
      if (element) {
        setLastAction(1)
        console.log('scrollIntoView', `section-${steps}`)
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' })
      }
    }
  }, [curIndex, lastAction])

  const styleCreate = useSpring({
    from: { display: 'none', opacity: 0 },
    opacity: isHovering ? 1 : 0,
    display: isHovering ? 'inline' : 'none',
    config: { duration: 200 }
  })

  const styleSell = useSpring({
    from: { display: 'none', opacity: 0 },
    opacity: isSellHovering ? 1 : 0,
    display: isSellHovering ? 'inline' : 'none',
    config: { duration: 200 }
  })
  const styleExplore = useSpring({
    from: { display: 'none', opacity: 0 },
    opacity: isExplorerHovering ? 1 : 0,
    display: isExplorerHovering ? 'inline' : 'none',
    config: { duration: 200 }
  })

  return (
    <PageWrapper>
      {topList && topList.length > 0 && (
        <S.Collection>
          {curIndex !== 0 && (
            <S.ArrowLeftPanel>
              <S.ArrowIcon onClick={handlePre} src={ArrowLeft} alt={'arrow-left'}></S.ArrowIcon>
            </S.ArrowLeftPanel>
          )}
          <S.StyledSwiper
            ref={parrentScroll}
            onScroll={(e) => {
              if (e.currentTarget.scrollLeft > 0) {
                if (e.currentTarget.scrollLeft + e.currentTarget.clientWidth >= e.currentTarget.scrollWidth) {
                  setCurIndex(topList.length - 1)
                } else {
                  for (let i = 0; i < topList.length; i++) {
                    const temp = document.getElementById(`section-${i}`)
                    if (temp) {
                      if (temp?.offsetLeft >= e.currentTarget.scrollLeft) {
                        setCurIndex(i)
                        break
                      }
                    }
                  }
                }

                setLastAction(1)
              }
            }}
          >
            {topList.map((item: any, index: any) => {
              return (
                <S.StyledSwiperSlider
                  onClick={() => {
                    console.log('mediaId', item.medias[0].mediaId)
                    history.push(`/detail/${item.medias[0].mediaId}`)
                  }}
                  id={`section-${index}`}
                  key={index}
                >
                  <TokenImage uri={item.tokenUrl}></TokenImage>
                </S.StyledSwiperSlider>
              )
            })}
          </S.StyledSwiper>
          {curIndex !== topList.length - 1 &&
            parrentScroll.current &&
            parrentScroll.current.scrollWidth > parrentScroll.current.clientWidth && (
              <S.ArrowRightPanel>
                <S.ArrowIcon onClick={handleNext} src={ArrowRight} alt={'arrow-right'}></S.ArrowIcon>
              </S.ArrowRightPanel>
            )}
        </S.Collection>
      )}
      <S.SloganPanel>
        <S.StyledMarquee gradient={false} speed={200}>
          A.I ART ON THE BLOCKCHAIN<span style={{ width: 200 }}></span>A.I ART ON THE BLOCKCHAIN
          <span style={{ width: 200 }}></span>
        </S.StyledMarquee>
      </S.SloganPanel>
      <S.MenuPanel>
        <S.LogoPanel>
          <img src={Logo} alt="AW3C" />
        </S.LogoPanel>
        <S.MenuContainer>
          <S.MenuItem>
            <S.MenuTitle>
              <S.TitleSpan
                onClick={() => {
                  history.push('/create', { mediaId: '' })
                }}
                onMouseEnter={() => {
                  setIsHovering(true)
                }}
                onMouseLeave={() => {
                  setIsHovering(false)
                }}
              >
                Create
              </S.TitleSpan>
              <S.HoverSpan style={styleCreate}>←</S.HoverSpan>
            </S.MenuTitle>
            <S.MenuSubTitle>Generate your own digital art through our platform!</S.MenuSubTitle>
          </S.MenuItem>
          <S.MenuItem>
            <S.MenuTitle
              onClick={() => {
                history.push('/sell')
              }}
              onMouseEnter={() => {
                setIsSellHovering(true)
              }}
              onMouseLeave={() => {
                setIsSellHovering(false)
              }}
            >
              <S.TitleSpan>Sell NFT</S.TitleSpan>
              <S.HoverSpan style={styleSell}>←</S.HoverSpan>
            </S.MenuTitle>
            <S.MenuSubTitle>
              Want to sell your AI artwork too? It&apos;s just a few simple steps away, and it&apos;s free!
            </S.MenuSubTitle>
          </S.MenuItem>
          <S.MenuItem>
            <S.MenuTitle
              onClick={() => {
                history.push('/collection')
              }}
              onMouseEnter={() => {
                setIsExplorerHovering(true)
              }}
              onMouseLeave={() => {
                setIsExplorerHovering(false)
              }}
            >
              <S.TitleSpan>Explore</S.TitleSpan>
              <S.HoverSpan style={styleExplore}>←</S.HoverSpan>
            </S.MenuTitle>
            <S.MenuSubTitle>Discover more works by artists from around the world.</S.MenuSubTitle>
          </S.MenuItem>
        </S.MenuContainer>
      </S.MenuPanel>
    </PageWrapper>
  )
}
