import { useWeb3React } from '@web3-react/core'
import AudioWavePlayer from 'components/AudioWavePlayer'
import { SupportedChainId } from 'constants/chains'
import { CustomizeEvent } from 'constants/events'
import { Status } from 'constants/opensea'
import { formatEther } from 'ethers/lib/utils'
import { useErc721Contract } from 'hooks/useContract'
import { useFetchTokenUriDataCallback } from 'hooks/useFetchDaoData'
import { fulfillInstantSaleOrder, makeInstantSaleOrder } from 'lib/opensea'
import moment from 'moment'
import { EventType, Network, OpenSeaPort } from 'opensea-js'
import * as S from 'pages/NFTDetail/styles'
import { useCallback, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useUnmount } from 'react-use'
import remarkGfm from 'remark-gfm'
import {
  useCreateOrderMutation,
  useGetAiMediaDetailMutation,
  useGetOrdersListMutation,
  useUpdateOrderMutation
} from 'state/ai/slice'
import { useAddPopup } from 'state/application/hooks'
import { useIsTransactionConfirmed } from 'state/transactions/hooks'
import { ExternalLink, PageWrapper } from 'theme'
import { dispatch as dispatchCustomEvent } from 'use-bus'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import Web3 from 'web3'

export default function NFTDetail({
  match: {
    params: { mediaId }
  }
}: RouteComponentProps<{ mediaId: string }>) {
  const history = useHistory()
  const { chainId, provider, account } = useWeb3React()

  const [curContract, setCurContract] = useState('')
  const [curTokenId, setCurTokenId] = useState('')
  const [curNftId, setCurNftId] = useState('')
  const [curOrderId, setCurOrderId] = useState('')
  const [openSeaPort, setOpenseaPort] = useState(null as any)
  const contractHandle = useErc721Contract(curContract, false)
  const [owner, setOwner] = useState('')
  const [tokenUrl, setTokenUrl] = useState('')
  const [order, setOrder] = useState(null as any)
  const [status, setStatus] = useState(Status.init)
  const [price, setPrice] = useState('' as any)

  const [transaction, setTransaction] = useState('')

  const [getAiMediaDetail] = useGetAiMediaDetailMutation()
  const [createOrder] = useCreateOrderMutation()
  const [updateOrder] = useUpdateOrderMutation()
  const [getOrders] = useGetOrdersListMutation()
  const addPopup = useAddPopup()

  useEffect(() => {
    if (provider) {
      console.log(provider, typeof provider)
      const temp =
        provider.provider ?? new Web3.providers.HttpProvider('https://infura.io/v3/9183fc9c73bb44dd84216ce1e76f81d0')
      console.log(temp, typeof temp)
      const openSeaPort = new OpenSeaPort(temp as any, {
        networkName: Network.Goerli
      })
      setOpenseaPort(openSeaPort)
    }
  }, [provider])

  useEffect(() => {
    if (mediaId) {
      getAiMediaDetail({ mediaId })
        .then((data: any) => {
          console.log('get detail', data)
          if (data?.data?.code === 0) {
            if (data?.data?.data?.isNFT) {
              setCurContract(data?.data?.data?.nftInfo[0].contract)
              setCurTokenId(data?.data?.data?.nftInfo[0].tokenId)
              setCurNftId(data?.data?.data?.nftInfo[0].nftId)
            } else {
              history.push('/create', { mediaId })
            }
          } else {
            addPopup({ error: 'get media info failed.' })
          }
        })
        .catch((e) => {
          console.error(e)
          addPopup({ error: 'get image detail failed.' })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaId])

  useEffect(() => {
    if (curNftId && order) {
      getOrders({ pageSize: 50, orderBy: 'DESC', status: 'onSell', nftId: curNftId })
        .then((data: any) => {
          console.log('getOrders', data)
          if (data?.data?.code === 0) {
            data?.data?.data?.data.forEach((item: any) => {
              if (item.context.orderHash === order.orderHash) {
                console.log('curOrderId', item.orderId)
                setCurOrderId(item.orderId)
              }
            })
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [curNftId, getOrders, order])

  useEffect(() => {
    if (contractHandle && curTokenId) {
      contractHandle
        .ownerOf(curTokenId)
        .then((data: any) => {
          console.log('owner', data)
          setOwner(data)
        })
        .catch((e: any) => console.error(e))
      contractHandle
        .tokenURI(curTokenId)
        .then((data: any) => {
          console.log('tokenUrl', data)
          setTokenUrl(data)
        })
        .catch((e: any) => {
          console.error(e)
        })
    }
  }, [contractHandle, curTokenId])

  useEffect(() => {
    if (curContract && curTokenId && openSeaPort) {
      openSeaPort?.api
        .getOrder({
          side: 'ask',
          tokenId: curTokenId,
          assetContractAddress: curContract
        })
        .then((data: any) => {
          console.log('order', data)
          setOrder(data)
        })
        .catch((e: any) => {
          console.error(e)
          setOrder(undefined)
        })
    }
  }, [addPopup, curContract, curTokenId, openSeaPort])

  const fetchData = useFetchTokenUriDataCallback()
  const [nftDetail, setNftDetail] = useState(null as any)
  useEffect(() => {
    if (tokenUrl) {
      fetchData(tokenUrl)
        .then((data) => {
          console.log('detail', data)
          setNftDetail(data)
        })
        .catch((error) => console.error('fetching data error', error))
    }
  }, [fetchData, tokenUrl])

  const isProcessing = Boolean(status) && status !== Status.error
  const isSuccess = Boolean(status === Status.complete)

  const isConfirmed = useIsTransactionConfirmed(transaction)

  useEffect(() => {
    if (isConfirmed) {
    }
  }, [isConfirmed])

  const handleSell = useCallback(async () => {
    if (isProcessing) return
    if (!price) return
    if (!openSeaPort || !openSeaPort.addListener) return
    setStatus(Status.start)

    makeInstantSaleOrder(openSeaPort, {
      account,
      tokenContract: curContract,
      tokenId: curTokenId,
      price
    })
      .then((order) => {
        console.log('sellOrder', order)
        createOrder({
          tokenId: Number(curTokenId),
          contract: curContract,
          orderType: 'aw3c',
          orderStatus: 'onSell',
          context: order
        })
          .then((data: any) => {
            console.log('create order:', data)
            if (data?.data?.code === 0) {
              setStatus(Status.complete)
            } else {
              setStatus(Status.error)
              addPopup({ error: 'Create Order failed, please retry.' })
            }
          })
          .catch((e) => {
            console.error(e)
            setStatus(Status.error)
            addPopup({ error: 'Create Order failed, please retry.' })
          })
      })
      .catch((e) => {
        setStatus(Status.error)
        console.log('makeInstantSaleOrder error', e)
        addPopup({ error: 'Create Order failed, please retry.' })
      })
  }, [account, addPopup, createOrder, curContract, curTokenId, isProcessing, openSeaPort, price])

  useUnmount(() => {
    if (openSeaPort && openSeaPort.removeAllListeners) {
      openSeaPort.removeAllListeners()
    }
  })

  const handleBuy = useCallback(() => {
    if (!account) {
      dispatchCustomEvent(CustomizeEvent.LOGIN_BY_SIGNED)
    } else {
      if (isProcessing) return
      if (!curOrderId) {
        addPopup({ error: 'Get Order Id failed, please refresh to retry.' })
        return
      }
      if (!openSeaPort || !openSeaPort.addListener) return
      setStatus(Status.start)

      openSeaPort.addListener(EventType.MatchOrders, () => {
        setStatus(Status.matchOrders)
        console.debug('MatchOrders')
      })
      openSeaPort.addListener(EventType.TransactionCreated, () => {
        setStatus(Status.transactionCreated)
        console.debug('TransactionCreated')
      })
      openSeaPort.addListener(EventType.TransactionConfirmed, ({ transactionHash }: any) => {
        setStatus(Status.transactionConfirmed)
        console.debug('TransactionConfirmed')
        if (transactionHash) {
          setTransaction(transactionHash)
        }
      })
      openSeaPort.addListener(EventType.TransactionDenied, ({ transactionHash, event }: any) => {
        setStatus(Status.error)
        console.debug('TransactionDenied')
        addPopup({ error: 'Buy failed, please retry.' })
      })
      openSeaPort.addListener(EventType.TransactionFailed, () => {
        setStatus(Status.error)
        console.debug('TransactionFailed')
        addPopup({ error: 'Buy failed, please retry.' })
      })
      fulfillInstantSaleOrder(openSeaPort, {
        account,
        order
      })
        .then((data) => {
          console.log('buyOrder', data)
          updateOrder({
            orderId: curOrderId,
            orderType: 'aw3c',
            orderStatus: 'unSell',
            context: order,
            tokenId: Number(curTokenId),
            contract: curContract,
            buyer: account
          })
            .then((data: any) => {
              console.log('create order:', data)
              if (data?.data?.code === 0) {
                setStatus(Status.complete)
              } else {
                setStatus(Status.error)
                addPopup({ error: 'Create Order failed, please retry.' })
              }
            })
            .catch((e) => {
              console.error(e)
              setStatus(Status.error)
              addPopup({ error: 'Create Order failed, please retry.' })
            })
        })
        .catch((e) => {
          setStatus(Status.error)
          console.log('fulfillInstantSaleOrder error', e)
          addPopup({ error: 'Buy failed, please retry.' })
        })
    }
  }, [account, isProcessing, curOrderId, openSeaPort, order, addPopup, updateOrder, curTokenId, curContract])

  const handleDownload = useCallback((url) => {
    fetch(url, { credentials: 'omit' })
      .then((response) => {
        console.log(response)
        if (response.ok) {
          response.blob().then(function (myBlob) {
            const elink = document.createElement('a')
            elink.download = url.substring(url.lastIndexOf('/') + 1)
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(myBlob)
            document.body.appendChild(elink)
            elink.click()
            document.body.removeChild(elink)
          })
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  return (
    <PageWrapper>
      {nftDetail && (
        <S.Content>
          {nftDetail.type === 'image' && (
            <S.ImagePanel>
              <img src={nftDetail.url} alt={nftDetail.name}></img>
            </S.ImagePanel>
          )}
          {nftDetail.type === 'audio' && (
            <S.AudioPanel>
              <AudioWavePlayer playSize={'10.3vh'} source={nftDetail.url} />
            </S.AudioPanel>
          )}
          <S.DetailPanel>
            <S.NFTName>{nftDetail.name}</S.NFTName>
            <S.Creator>{nftDetail.creator}</S.Creator>

            <S.Time>
              {moment(nftDetail.generate_info.timestamp).format('DD MMM yyyy, HH:mm ')}
              {/* <S.GMTTime>{moment(nftDetail.generate_info.timestamp).format('[GMT]ZZ').slice(0, -2)}</S.GMTTime> */}
            </S.Time>

            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                // eslint-disable-next-line react/display-name
                a: ({ ...props }) => <ExternalLink href="" {...props} />
              }}
            >
              {nftDetail.description}
            </ReactMarkdown>
            <div>
              License:{' '}
              <S.StyledExtLink href={nftDetail.generate_info.license}>
                {nftDetail.generate_info.license}
              </S.StyledExtLink>
            </div>

            <div style={{ marginTop: '2.42vh' }}>
              <S.StyledExtLink
                href={getExplorerLink(
                  chainId ?? SupportedChainId.MAINNET,
                  `${curContract}?a=${curTokenId}`,
                  ExplorerDataType.TOKEN
                )}
              >
                View on the Blockchain
              </S.StyledExtLink>
            </div>

            {nftDetail?.type === 'audio' && (
              <S.Download
                onClick={() => {
                  handleDownload(nftDetail.url)
                }}
              >
                Download
              </S.Download>
            )}
            {account &&
              owner.toLocaleLowerCase() === account?.toLocaleLowerCase() &&
              order === undefined &&
              !isSuccess && (
                <S.SellPanel disabled={isProcessing}>
                  <S.SellInput>
                    <S.PriceInput
                      value={price}
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off"
                      spellCheck="false"
                      autoFocus
                      onChange={(event) => {
                        const input = event.target.value
                        setPrice(input)
                      }}
                      disabled={isProcessing}
                      type="number"
                    ></S.PriceInput>
                    <S.SymbolLabel>ETH</S.SymbolLabel>
                  </S.SellInput>
                  <S.SellButton onClick={handleSell}>Sell</S.SellButton>
                </S.SellPanel>
              )}
            {isSuccess && account && (
              <S.SellSuccessPanel>
                <S.Congratulation>Congratulations!</S.Congratulation>
                {owner?.toLocaleLowerCase() === account?.toLocaleLowerCase() && (
                  <>
                    <div>You’re selling this artwork at</div>
                    <S.Price>{price} ETH</S.Price>
                    <div>Good luck!</div>
                  </>
                )}
              </S.SellSuccessPanel>
            )}
            {order && !isSuccess && (
              <S.BuyPanel>
                <S.BuyPrice>PRICE {formatEther(order?.currentPrice).toString()} ETH</S.BuyPrice>
                {owner?.toLocaleLowerCase() !== account?.toLocaleLowerCase() && (
                  <S.BuyButton disabled={isProcessing} onClick={handleBuy}>
                    Buy
                  </S.BuyButton>
                )}
              </S.BuyPanel>
            )}

            <S.BackButton
              onClick={() => {
                history.goBack()
              }}
            >
              Back
            </S.BackButton>
          </S.DetailPanel>
        </S.Content>
      )}
    </PageWrapper>
  )
}
