import React from 'react'
import styled from 'styled-components/macro'

export const BodyWrapper = styled.main<{ margin?: string; maxWidth?: string }>`
  background: ${({ theme }) => theme.bg0};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
  /* 滚动槽 */
  ::-webkit-scrollbar {
    width: 0.16vw;
    height: 0.16vw;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0.08vw;
  }
  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    border-radius: 0.08vw;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({
  children,
  onScroll,
  scrollRef,
  ...rest
}: {
  children: React.ReactNode
  scrollRef: any
  onScroll: (e: any) => void
}) {
  return (
    <BodyWrapper ref={scrollRef} onScroll={onScroll} {...rest}>
      {children}
    </BodyWrapper>
  )
}
