import { TransactionResponse } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { useErc721Contract } from 'hooks/useContract'
import { useCallback } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'

export function useMintCallback(controllerContract: string): {
  mintCallback: () => undefined | Promise<string>
} {
  const { account, chainId } = useWeb3React()
  const addTransaction = useTransactionAdder()
  const contract = useErc721Contract(controllerContract, true)

  const mintCallback = useCallback(() => {
    if (!chainId || !account) return undefined
    if (!contract) throw new Error('No Factory Contract!')
    return contract.estimateGas.mint().then((estimatedGasLimit) => {
      return contract
        .mint({
          gasLimit: calculateGasMargin(estimatedGasLimit)
        })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            type: TransactionType.SAFE_MINT,
            to: account
          })
          return response.hash
        })
    })
  }, [chainId, account, contract, addTransaction])
  return { mintCallback }
}
