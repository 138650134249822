import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import { useSpring } from '@react-spring/web'
import { useWeb3React } from '@web3-react/core'
import { ReactComponent as Adjust } from 'assets/svg/icon-adjust.svg'
import { ReactComponent as Check } from 'assets/svg/icon-check.svg'
import AudioLinearPlayer from 'components/AudioLinearPlayer'
import AudioWavePlayer from 'components/AudioWavePlayer'
import { TextInput } from 'components/TextInput'
import TokenCard from 'components/TokenCard'
import { AIGenerateType, AIImageEngine } from 'constants/ai'
import { SupportedChainId } from 'constants/chains'
import { CustomizeEvent } from 'constants/events'
import { BigNumber } from 'ethers/lib/ethers'
import useAccess from 'hooks/useAccess'
import { useErc721Contract } from 'hooks/useContract'
import { useMintCallback } from 'hooks/useTokenContractCallback'
import { useWindowSize } from 'hooks/useWindowSize'
import { makeInstantSaleOrder } from 'lib/opensea'
import { Network, OpenSeaPort } from 'opensea-js'
import * as S from 'pages/Create/styles'
import { useCallback, useEffect, useRef, useState } from 'react'
// import InfiniteScroll from 'react-infinite-scroller'
import Masonry from 'react-responsive-masonry'
import { useHistory, useLocation } from 'react-router-dom'
import {
  useCreateOrderMutation,
  useGenerateMusicMutation,
  useGetAiMediaDetailMutation,
  useGetNftsListMutation,
  useMintFinishMutation,
  useOpenAIMutation,
  useTextToImageMutation
} from 'state/ai/slice'
import { useAddPopup, useWalletModalToggle } from 'state/application/hooks'
import { useIsTransactionConfirmed, useTransaction } from 'state/transactions/hooks'
import { useTheme } from 'styled-components/macro'
import { MEDIA_WIDTHS, PageWrapper } from 'theme'
import useBus, { dispatch as dispatchCustomEvent } from 'use-bus'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { fnGetCpmisWords, fnGetPrefixWords } from 'utils/validators'
import Web3 from 'web3'

const ParamSlider = styled(Slider)(({ theme, disabled }) => ({
  color: 'rgba(250, 250, 250, 0.22)',
  height: 1,
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: disabled ? 'rgba(250, 250, 250, 0.22)' : '#00FF0A'
  },
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: '#D9D9D9',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  }
}))

export default function Create() {
  const theme = useTheme()
  const history = useHistory()
  const { state } = useLocation()
  const { width } = useWindowSize()
  const { account, chainId, provider } = useWeb3React()
  const [promptText, setPromptText] = useState('')
  const [negativePromptText, setNegativePromptText] = useState('')
  const [imageScale, setImageScale] = useState(5)
  const [imageWidth, setImageWidth] = useState(512)
  const [imageHeight, setImageHeight] = useState(512)
  const [genType, setGenType] = useState(AIGenerateType.IMAGE)
  const [imageEngine, setImageEngine] = useState(AIImageEngine.SD)
  const [generatedImages, setGeneratedImages] = useState([] as any[])
  const [generating, setGenerating] = useState(false)
  const [generatePercent, setGeneratePercent] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [showAdvance, setShowAdvance] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const [selected, setSelected] = useState('')
  const [selDetail, setSelDetail] = useState(null as any)
  const [likeList, setLikeList] = useState([] as any[])
  const [minting, setMinting] = useState(false)
  const [creatorName, setCreatorName] = useState('')
  const [nftName, setNftName] = useState('')
  const [description, setDescription] = useState('')
  const [creatorNameError, setCreatorNameError] = useState('')
  const [nftNameError, setNftNameError] = useState('')
  const [descriptionError, setDescriptionError] = useState('')
  const [allowPrice, setAllowPrice] = useState(false)
  const [allowLicense, setAllowLicense] = useState(false)
  const [mintSuccess, setMintSuccess] = useState(false)
  const [txHash, setTxHash] = useState('')
  const [tokenId, setTokenId] = useState('')
  const [contract] = useState('0xbCbCFe01803F2b5f83753A1448B0AF29b71eF568')

  const [price, setPrice] = useState('')
  const [openSeaPort, setOpenseaPort] = useState(null as any)

  const [url, setUrl] = useState('')
  const [urlMusic, setMusicUrl] = useState('')

  // openAI param
  const [imageNumber] = useState(4)

  // music param
  const [promptMusic, setPromptMusic] = useState('')
  const email = 'xxqdd1@163.com'
  const [duration, setDuration] = useState(50)
  const [loop, setLoop] = useState(false)
  const [generatedMusic, setGeneratedMusic] = useState([] as any[])

  const [cursor, serCursor] = useState('')
  const pageSize = 24
  const throttling = useRef(false)

  const addPopup = useAddPopup()

  const toggleWalletModal = useWalletModalToggle()
  const access = useAccess()

  const [textToImage] = useTextToImageMutation()
  const [textToMusic] = useGenerateMusicMutation()
  const [generateOpenAI] = useOpenAIMutation()
  const [getAiMediaDetail] = useGetAiMediaDetailMutation()
  const [getAIList] = useGetNftsListMutation()
  const [mintFinish] = useMintFinishMutation()
  const { mintCallback } = useMintCallback(contract)
  const [createOrder] = useCreateOrderMutation()

  const imageAdvanceSpring = useSpring({
    from: { height: 0, display: 'none' },
    height: showAdvance ? 'fit-content' : 0,
    display: showAdvance ? 'block' : 'none',
    config: { duration: 1000 }
  })

  const txConfirmed = useIsTransactionConfirmed(txHash)
  const transaction = useTransaction(txHash)
  const tokenContract = useErc721Contract(contract)

  useEffect(() => {
    if (state && (state as any)?.mediaId) {
      setSelected((state as any)?.mediaId)
      setShowDetail(true)
    }
  }, [state])

  useEffect(() => {
    if (provider) {
      console.log(provider, typeof provider)
      const temp =
        provider.provider ?? new Web3.providers.HttpProvider('https://infura.io/v3/9183fc9c73bb44dd84216ce1e76f81d0')
      console.log(temp, typeof temp)
      const openSeaPort = new OpenSeaPort(temp as any, {
        networkName: Network.Goerli
      })
      setOpenseaPort(openSeaPort)
    }
  }, [provider])

  const handleSell = useCallback(
    async (tokenId: string) => {
      if (!openSeaPort || !openSeaPort.addListener) {
        setMinting(false)
        addPopup({ error: 'marketplace initial failed, please retry.' })
        return
      }

      makeInstantSaleOrder(openSeaPort, {
        account,
        tokenContract: contract,
        tokenId,
        price
      })
        .then((order) => {
          console.log('sellOrder', order)
          createOrder({
            tokenId: Number(tokenId),
            contract,
            orderType: 'aw3c',
            orderStatus: 'onSell',
            context: order
          })
            .then((data: any) => {
              console.log('create order:', data)
              if (data?.data?.code === 0) {
                setMintSuccess(true)
                setShowDetail(false)
                setMinting(false)
              } else {
                setMinting(false)
                addPopup({ error: 'Submit failed, please retry.' })
              }
            })
            .catch((e) => {
              console.error(e)
              setMinting(false)
              addPopup({ error: 'Submit failed, please retry.' })
            })
        })
        .catch((e) => {
          console.log('makeInstantSaleOrder error', e)
          setMinting(false)
          addPopup({ error: 'Submit failed, please retry.' })
        })
    },
    [account, addPopup, contract, createOrder, openSeaPort, price]
  )

  useEffect(() => {
    if (txConfirmed) {
      console.log('transaction', transaction, transaction?.receipt?.blockNumber)
      tokenContract
        ?.queryFilter(tokenContract?.filters.Mint(), transaction?.receipt?.blockNumber)
        .then((events) => {
          console.log('events', events)
          events.forEach((event: any) => {
            console.log('event', event.args[0], event.args[1])
            if (event.args[0] === account) {
              console.log('find event')
              setTokenId(BigNumber.from(event.args[1]).toString())

              mintFinish({
                contractAddr: contract,
                tokenId: BigNumber.from(event.args[1]).toString(),
                mediaId: selDetail.mediaId,
                nftName,
                creator: creatorName,
                description
              })
                .then((data) => {
                  console.log('mint finish', data)

                  if (price) {
                    handleSell(BigNumber.from(event.args[1]).toString())
                  } else {
                    setMintSuccess(true)
                    setShowDetail(false)
                    setMinting(false)
                  }
                })
                .catch((e) => {
                  console.error(e)
                  setMinting(false)
                })
            }
          })
        })
        .catch((err) => {
          console.log(err)
          setMinting(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintFinish, txConfirmed])

  const clearDetailInfo = useCallback(() => {
    setSelDetail(null)
    setTxHash('')
    setAllowLicense(false)
    setAllowPrice(false)
    setMintSuccess(false)
    setShowDetail(false)
    setMinting(false)
    setCreatorNameError('')
    setCreatorName('')
    setNftNameError('')
    setNftName('')
    setDescriptionError('')
    setDescription('')
    setSelected('')
  }, [])

  const handleMint = useCallback(async () => {
    if (!mintCallback || minting) return
    if (creatorName.length === 0) {
      setCreatorNameError('Please enter creator name(s)')
    }
    if (nftName.length === 0) {
      setNftNameError('Please enter nft name')
    }
    if (fnGetCpmisWords(description) < 1) {
      setDescriptionError('The description should be between 1 and 200 words.')
    }
    if (!allowLicense || !allowPrice) {
      addPopup({ error: 'Please check the acknowledge.' })
    }
    if (
      creatorName.length === 0 ||
      nftName.length === 0 ||
      fnGetCpmisWords(description) < 1 ||
      !allowLicense ||
      !allowPrice
    ) {
      console.log(creatorNameError || nftNameError || descriptionError || !allowLicense || !allowPrice)
      return
    }
    setMinting(true)
    try {
      const hash = await mintCallback()
      setTxHash(hash ?? '')
    } catch (error) {
      setMinting(false)
      addPopup({
        error:
          'Mint Failed, Please check error: ' + (error.reason ? error.reason : error.message ? error.message : error)
      })
    }
  }, [
    addPopup,
    allowLicense,
    allowPrice,
    creatorName.length,
    creatorNameError,
    description,
    descriptionError,
    mintCallback,
    minting,
    nftName.length,
    nftNameError
  ])

  const handleGenerate = useCallback(() => {
    console.log('handle generate ai')
    if (!account || !provider) {
      toggleWalletModal()
      return
    } else if (!access) {
      dispatchCustomEvent(CustomizeEvent.LOGIN_BY_SIGNED)
      return
    }
    if (genType === AIGenerateType.IMAGE) {
      if (promptText.length <= 0) {
        addPopup({ error: 'Describe your artwork first.' })
        return
      }
      setGeneratePercent(0)
      setGenerating(true)
      if (imageEngine === AIImageEngine.OPENAI) {
        const param: any = {
          prompt: promptText,
          n: imageNumber
        }
        generateOpenAI(param)
          .then((data: any) => {
            console.log('generateOpenAI', data)
            if (data?.data?.code === 0) {
              setSelDetail(null)
              setGeneratePercent(1)
              setGenerating(false)
              setGeneratedImages(data?.data?.data?.images)
            } else {
              setGenerating(false)

              addPopup({ error: 'Generate error.' })
            }
          })
          .catch((e) => {
            console.error(e)
            setGenerating(false)
            addPopup({ error: 'Generate error.' })
          })
      } else {
        let param: any = {
          prompt: promptText,
          url
        }
        if (showAdvance) {
          param = {
            ...param,
            negativePrompt: negativePromptText,
            cfgScale: imageScale,
            width: imageWidth,
            height: imageHeight
          }
        }
        textToImage(param)
          .then((data: any) => {
            console.log('text2Img', data)
            if (data?.data?.code === 0) {
              setSelDetail(null)
              setGeneratePercent(1)
              setGenerating(false)
              setGeneratedImages(data?.data?.data?.images)
            } else {
              setGenerating(false)

              addPopup({ error: 'Generate error.' })
            }
          })
          .catch((e) => {
            console.error(e)
            setGenerating(false)
            addPopup({ error: 'Generate error.' })
          })
      }
    } else if (genType === AIGenerateType.MUSIC) {
      if (promptMusic.length <= 0) {
        addPopup({ error: 'Describe your music first.' })
        return
      }
      setGeneratePercent(0)
      setGenerating(true)
      const param: any = {
        prompt: promptMusic,
        url: urlMusic,
        email,
        duration,
        loop
      }

      textToMusic(param)
        .then((data: any) => {
          console.log('textToMusic', data)
          if (data?.data?.code === 0) {
            setSelDetail(null)
            setGeneratePercent(1)
            setGenerating(false)
            setGeneratedMusic(data?.data?.data?.audios)
          } else {
            setGenerating(false)

            addPopup({ error: 'Generate error.' })
          }
        })
        .catch((e) => {
          console.error(e)
          setGenerating(false)
          addPopup({ error: 'Generate error.' })
        })
    } else {
      return
    }
    setTimeout(() => {
      setGeneratePercent(0.1)
    }, 1000)

    setTimeout(() => {
      setGeneratePercent(0.5)
    }, 2000)
    setTimeout(() => {
      setGeneratePercent(0.7)
    }, 3000)
  }, [
    access,
    account,
    addPopup,
    duration,
    genType,
    generateOpenAI,
    imageEngine,
    imageHeight,
    imageNumber,
    imageScale,
    imageWidth,
    loop,
    negativePromptText,
    promptMusic,
    promptText,
    provider,
    showAdvance,
    textToImage,
    textToMusic,
    toggleWalletModal,
    url,
    urlMusic
  ])

  useEffect(() => {
    if (selected) {
      getAiMediaDetail({ mediaId: selected })
        .then((data: any) => {
          console.log('get detail', data)
          if (data?.data?.code === 0) {
            setSelDetail(data?.data?.data)
          } else {
            setSelected('')
            addPopup({ error: 'get image detail failed.' })
          }
        })
        .catch((e) => {
          console.error(e)
          setSelected('')
          addPopup({ error: 'get image detail failed.' })
        })
    }
  }, [addPopup, getAiMediaDetail, selected])

  const loadData = useCallback(
    (current: string, type: string) => {
      getAIList({ afterCursor: current, pageSize, orderBy: 'DESC', type })
        .then((data: any) => {
          throttling.current = false
          console.log('ailist', data, data?.data?.data?.data.length)
          if (data?.data?.code === 0) {
            serCursor(data?.data?.data?.afterCursor ?? data?.data?.data?.beforeCursor)
            if (current) {
              setLikeList(likeList.concat(data?.data?.data?.data))
            } else {
              setLikeList([].concat(data?.data?.data?.data))
            }
            if (data?.data?.data?.data.length < pageSize) {
              setHasMore(false)
              console.log('set hasmore false')
            }
          } else {
            addPopup({ error: 'Get AI list failed, please retry.' })
          }
        })
        .catch((e) => {
          console.error(e)
          throttling.current = false
          addPopup({ error: 'Get AI list failed, please retry.' })
        })
    },
    [addPopup, getAIList, likeList]
  )

  const getLoadType = useCallback(() => {
    if (genType === AIGenerateType.IMAGE) {
      return 'image'
    } else if (genType === AIGenerateType.MUSIC) {
      return 'audio'
    } else {
      return 'all'
    }
  }, [genType])

  useEffect(() => {
    serCursor('')
    loadData('', getLoadType())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genType])

  const LoadMore = useCallback(() => {
    if (throttling.current || !hasMore) {
      return
    }
    throttling.current = true
    setTimeout(() => {
      loadData(cursor, getLoadType())
    }, 200)
  }, [hasMore, loadData, cursor, getLoadType])

  useBus(
    CustomizeEvent.SCROLL_TO_BOTTOM,
    () => {
      console.log('scroll to bottom')
      LoadMore()
    },
    [cursor, hasMore, loadData]
  )

  const handleDownload = useCallback((url) => {
    fetch(url, { credentials: 'omit' })
      .then((response) => {
        console.log(response)
        if (response.ok) {
          response.blob().then(function (myBlob) {
            const elink = document.createElement('a')
            elink.download = url.substring(url.lastIndexOf('/') + 1)
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(myBlob)
            document.body.appendChild(elink)
            elink.click()
            document.body.removeChild(elink)
          })
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }, [])

  return (
    <PageWrapper>
      <S.GeneratePanel>
        <S.TypeTabs>
          <S.TypeTab
            active={genType === AIGenerateType.IMAGE}
            onClick={() => {
              setGenType(AIGenerateType.IMAGE)
            }}
          >
            Image
          </S.TypeTab>
          <S.TypeTab
            active={genType === AIGenerateType.MUSIC}
            onClick={() => {
              setGenType(AIGenerateType.MUSIC)
            }}
          >
            Music
          </S.TypeTab>
          {/* <S.TypeTab
            active={genType === AIGenerateType.OPENAI}
            onClick={() => {
              setGenType(AIGenerateType.OPENAI)
            }}
          >
            OpenAI
          </S.TypeTab> */}
          <S.TypeDisableTab>
            Video<S.ComingSoon>Coming soon</S.ComingSoon>
          </S.TypeDisableTab>
          <S.TypeDisableTab>
            3D<S.ComingSoon>Coming soon</S.ComingSoon>
          </S.TypeDisableTab>
          <S.TypeDisableTab>
            VR<S.ComingSoon>Coming soon</S.ComingSoon>
          </S.TypeDisableTab>
        </S.TypeTabs>
        {genType === AIGenerateType.IMAGE && (
          <S.GenerateActionPanel>
            <S.GenerateLabel disabled={generating}>Choose AI Engine</S.GenerateLabel>
            <S.ImageEnginePanel>
              <S.ImageEngineButton
                onClick={() => {
                  if (generating) return
                  setImageEngine(AIImageEngine.SD)
                }}
                active={imageEngine === AIImageEngine.SD}
                disabled={generating}
              >
                Stable Diffusion
              </S.ImageEngineButton>
              <S.ImageEngineButton
                onClick={() => {
                  if (generating) return
                  if (showAdvance) {
                    setShowAdvance(false)
                  }
                  setImageEngine(AIImageEngine.OPENAI)
                }}
                style={{ marginLeft: '1.91vw' }}
                active={imageEngine === AIImageEngine.OPENAI}
                disabled={generating}
              >
                OpenAI
              </S.ImageEngineButton>
            </S.ImageEnginePanel>
            {imageEngine === AIImageEngine.SD && (
              <>
                <S.GenerateLabel disabled={generating}>Test Url</S.GenerateLabel>
                <TextInput
                  value={url}
                  onUserInput={(value) => {
                    setUrl(value)
                  }}
                  placeholder={'Enter test url here'}
                  fontSize={'1.43vh'}
                  disabled={generating}
                />
              </>
            )}
            <S.GenerateLabel disabled={generating}>describe your Artwork</S.GenerateLabel>
            <S.PromptPanel>
              <TextInput
                value={promptText}
                onUserInput={(value) => {
                  setPromptText(value)
                }}
                placeholder={'Enter your prompt here'}
                fontSize={'1.43vh'}
                disabled={generating}
              />
              {imageEngine === AIImageEngine.SD && (
                <S.AdjustIcon
                  onClick={() => {
                    if (generating) return
                    setShowAdvance(!showAdvance)
                  }}
                >
                  <Adjust stroke={showAdvance ? theme.text3 : theme.text2}></Adjust>
                </S.AdjustIcon>
              )}
            </S.PromptPanel>
            <S.ImageAdvancePanel style={imageAdvanceSpring}>
              <S.GenerateLabel disabled={generating}>Negative Prompt</S.GenerateLabel>
              <TextInput
                value={negativePromptText}
                onUserInput={(value) => {
                  setNegativePromptText(value)
                }}
                placeholder={'Enter things to exclude'}
                fontSize={'1.43vh'}
                disabled={generating}
              />
              <S.ImageParamsPanel>
                <S.ParamItem style={{ marginRight: '3.01vw' }}>
                  <S.ParamLabelPanel>
                    <S.GenerateLabel style={{ flex: 1 }} disabled={generating}>
                      Guidance scale
                    </S.GenerateLabel>
                    <S.ParamValue disabled={generating}>{imageScale}</S.ParamValue>
                  </S.ParamLabelPanel>
                  <ParamSlider
                    value={imageScale}
                    step={1}
                    min={1}
                    max={20}
                    disabled={generating}
                    onChange={(event: any) => {
                      setImageScale(event?.target?.value)
                    }}
                  ></ParamSlider>
                  <S.ParamSliderInfo>
                    <S.MinParam>Better quality</S.MinParam>
                    <S.MaxParam>Match prompt</S.MaxParam>
                  </S.ParamSliderInfo>
                </S.ParamItem>
                <S.ParamItem style={{ marginLeft: '3.01vw', marginRight: '3.01vw' }}>
                  <S.ParamLabelPanel>
                    <S.GenerateLabel style={{ flex: 1 }} disabled={generating}>
                      Width
                    </S.GenerateLabel>
                    <S.ParamValue disabled={generating}>{imageWidth} px</S.ParamValue>
                  </S.ParamLabelPanel>
                  <ParamSlider
                    value={imageWidth}
                    step={32}
                    min={32}
                    max={2048}
                    onChange={(event: any) => {
                      setImageWidth(event?.target?.value)
                    }}
                    disabled={generating}
                  ></ParamSlider>
                  <S.ParamSliderInfo>
                    <S.MinParam>32 px</S.MinParam>
                    <S.MaxParam>2048 px</S.MaxParam>
                  </S.ParamSliderInfo>
                </S.ParamItem>
                <S.ParamItem style={{ marginLeft: '3.01vw' }}>
                  <S.ParamLabelPanel>
                    <S.GenerateLabel style={{ flex: 1 }} disabled={generating}>
                      Height
                    </S.GenerateLabel>
                    <S.ParamValue disabled={generating}>{imageHeight} px</S.ParamValue>
                  </S.ParamLabelPanel>
                  <ParamSlider
                    step={32}
                    min={32}
                    max={2048}
                    value={imageHeight}
                    onChange={(event: any) => {
                      setImageHeight(event?.target?.value)
                    }}
                    disabled={generating}
                  />
                  <S.ParamSliderInfo>
                    <S.MinParam>32 px</S.MinParam>
                    <S.MaxParam>2048 px</S.MaxParam>
                  </S.ParamSliderInfo>
                </S.ParamItem>
              </S.ImageParamsPanel>
            </S.ImageAdvancePanel>
            <S.GenerateButton onClick={handleGenerate} generating={generating} percent={generatePercent}>
              <span>{generating ? 'Generating' : 'Generate'}</span>
            </S.GenerateButton>

            {generatedImages.length > 0 && (
              <S.GeneratedImagePanel>
                {generatedImages.map((item: any, index) => {
                  return (
                    <S.GeneratedImage
                      lastOne={index === generatedImages.length - 1}
                      key={index}
                      onClick={() => {
                        setSelected(item?.fileName)
                        setShowDetail(true)
                      }}
                    >
                      <img src={item?.thumb} alt={'generated' + index}></img>
                    </S.GeneratedImage>
                  )
                })}
              </S.GeneratedImagePanel>
            )}
          </S.GenerateActionPanel>
        )}

        {genType === AIGenerateType.MUSIC && (
          <S.GenerateActionPanel>
            <S.GenerateLabel disabled={generating}>Test Url</S.GenerateLabel>
            <TextInput
              value={urlMusic}
              onUserInput={(value) => {
                setMusicUrl(value)
              }}
              placeholder={'Enter test url here'}
              fontSize={'1.43vh'}
              disabled={generating}
            />
            <S.GenerateLabel disabled={generating}>describe your music</S.GenerateLabel>
            <S.PromptPanel>
              <TextInput
                value={promptMusic}
                onUserInput={(value) => {
                  setPromptMusic(value)
                }}
                placeholder={'Enter your prompt here'}
                fontSize={'1.43vh'}
                disabled={generating}
              />
            </S.PromptPanel>

            <S.MusicParamsPanel>
              <S.ParamItem style={{ flex: 5 }}>
                <S.ParamLabelPanel>
                  <S.GenerateLabel style={{ flex: 1 }} disabled={generating}>
                    Duration
                  </S.GenerateLabel>
                  <S.ParamValue disabled={generating}>{duration}s</S.ParamValue>
                </S.ParamLabelPanel>
                <ParamSlider
                  step={1}
                  min={10}
                  max={100}
                  value={duration}
                  onChange={(event: any) => {
                    setDuration(event?.target?.value)
                  }}
                  disabled={generating}
                />
                <S.ParamSliderInfo>
                  <S.MinParam>10s</S.MinParam>
                  <S.MaxParam>100s</S.MaxParam>
                </S.ParamSliderInfo>
              </S.ParamItem>
              <S.LoopItem
                onClick={() => {
                  if (minting) return
                  setLoop(!loop)
                }}
              >
                <S.CheckIcon>
                  <Check width={'100%'} height={'100%'} fill={loop ? theme.text3 : theme.grey1}></Check>
                </S.CheckIcon>
                <S.GenerateLabel style={{ flex: 1, margin: 0, marginLeft: '0.93vw' }} disabled={generating}>
                  Generate loop
                </S.GenerateLabel>
              </S.LoopItem>
            </S.MusicParamsPanel>
            <S.GenerateButton onClick={handleGenerate} generating={generating} percent={generatePercent}>
              <span>{generating ? 'Generating' : 'Generate'}</span>
            </S.GenerateButton>
            {generatedMusic && generatedMusic.length > 0 && (
              <>
                {generatedMusic.map((item, index) => {
                  return (
                    <S.GeneratedMusicPanel key={index}>
                      <S.AudioPanel>
                        <AudioLinearPlayer source={item.url} />
                      </S.AudioPanel>
                      <S.MintMusic
                        onClick={() => {
                          setSelected(item?.fileName)
                          setShowDetail(true)
                        }}
                      >
                        Mint
                      </S.MintMusic>
                    </S.GeneratedMusicPanel>
                  )
                })}
              </>
            )}
          </S.GenerateActionPanel>
        )}

        {showDetail && !mintSuccess && (
          <S.GenDetailModal>
            <S.GenDetailPanel>
              <S.DetailBackButton
                onClick={() => {
                  if (minting) return
                  setShowDetail(false)
                  clearDetailInfo()
                }}
              >
                Back
              </S.DetailBackButton>
              {selDetail && (
                <S.DetailInfoPanel>
                  {selDetail?.type === 'image' && (
                    <S.DetailImagePanel>
                      <img src={selDetail.url} alt="detail" />
                    </S.DetailImagePanel>
                  )}
                  {selDetail?.type === 'audio' && (
                    <S.DetailMusicPanel>
                      <AudioWavePlayer playSize="10.3vh" source={selDetail.url} />
                    </S.DetailMusicPanel>
                  )}
                  <S.DetailRightPanel>
                    {selDetail?.type === 'image' && !selDetail?.prompt?.context?.parameters?.size && (
                      <S.ImageInfoPanel>
                        <S.ImageInfoTitle>Seed</S.ImageInfoTitle>
                        <S.ImageInfoContent>{JSON.parse(selDetail?.prompt?.context.info).seed}</S.ImageInfoContent>
                        <S.ImageInfoTitle style={{ marginTop: '1.79vh' }}>Guidance scale</S.ImageInfoTitle>
                        <S.ImageInfoContent>{selDetail?.prompt?.context?.parameters?.cfg_scale}</S.ImageInfoContent>
                        <S.ImageInfoTitle style={{ marginTop: '1.79vh' }}>Dimensions</S.ImageInfoTitle>
                        <S.ImageInfoContent>
                          {selDetail?.prompt?.context?.parameters?.width} x{' '}
                          {selDetail?.prompt?.context?.parameters?.height}
                        </S.ImageInfoContent>
                      </S.ImageInfoPanel>
                    )}
                    {selDetail?.type === 'image' && selDetail?.prompt?.context?.parameters?.size && (
                      <S.ImageInfoPanel>
                        <S.ImageInfoTitle>Dimensions</S.ImageInfoTitle>
                        <S.ImageInfoContent>{selDetail?.prompt?.context?.parameters?.size}</S.ImageInfoContent>
                      </S.ImageInfoPanel>
                    )}
                    {selDetail?.type === 'audio' && (
                      <S.ImageInfoPanel>
                        <S.ImageInfoTitle>Duration</S.ImageInfoTitle>
                        <S.ImageInfoContent>{selDetail?.prompt?.context?.parameters?.duration}s</S.ImageInfoContent>
                      </S.ImageInfoPanel>
                    )}
                    {selDetail?.type === 'image' && (
                      <S.Download>
                        <a href={selDetail.url}>Download</a>
                      </S.Download>
                    )}

                    {selDetail?.type === 'audio' && (
                      <S.Download
                        onClick={() => {
                          handleDownload(selDetail.url)
                        }}
                      >
                        Download
                      </S.Download>
                    )}

                    {selDetail?.isNFT ? (
                      selDetail?.nftInfo && selDetail?.nftInfo.length > 0 ? (
                        <S.ViewToken>
                          It has been minted. You can{' '}
                          <S.StyledInLink
                            onClick={() => {
                              history.push(`/detail/${selDetail?.mediaId}`)
                            }}
                          >
                            here
                          </S.StyledInLink>{' '}
                          click to view token.
                        </S.ViewToken>
                      ) : (
                        <></>
                      )
                    ) : (
                      <>
                        <S.MintInfoPanel>
                          <S.MintTitle>Mint as a NFT</S.MintTitle>
                          <S.MintLabel disabled={minting}>
                            1. Creator Name(s) <S.CountSpan>({30 - creatorName.length})</S.CountSpan>
                          </S.MintLabel>
                          <TextInput
                            value={creatorName}
                            onUserInput={(value) => {
                              if (creatorNameError) {
                                setCreatorNameError('')
                              }
                              let input = value
                              if (input.length > 30) {
                                input = input.substring(0, 30)
                                setCreatorNameError('The creator name(s) should be no more than 30 characters.')
                              }
                              setCreatorName(input)
                            }}
                            placeholder={'This is how your name will appear on AW3C.'}
                            fontSize={'1.43vh'}
                            disabled={minting}
                          />
                          <S.MintInputError>{creatorNameError}</S.MintInputError>
                          <S.MintLabel disabled={minting}>
                            2. NFT Name <S.CountSpan>({30 - nftName.length})</S.CountSpan>
                          </S.MintLabel>
                          <TextInput
                            value={nftName}
                            onUserInput={(value) => {
                              if (nftNameError) {
                                setNftNameError('')
                              }
                              let input = value
                              if (input.length > 30) {
                                input = input.substring(0, 30)
                                setNftNameError('The nft name should be no more than 30 characters.')
                              }
                              setNftName(input)
                            }}
                            placeholder={'This is the public name of your collection.'}
                            fontSize={'1.43vh'}
                            disabled={minting}
                          />
                          <S.MintInputError>{nftNameError}</S.MintInputError>
                          <S.MintLabel disabled={minting}>
                            3. NFT Description <S.CountSpan>({200 - fnGetCpmisWords(description)})</S.CountSpan>
                          </S.MintLabel>
                          <TextInput
                            value={description}
                            onUserInput={(value) => {
                              if (descriptionError) {
                                setDescriptionError('')
                              }
                              let input = value
                              if (fnGetCpmisWords(value) > 200) {
                                input = fnGetPrefixWords(value, 200)
                                setDescriptionError('The description should be between 1 and 200 words.')
                              }
                              setDescription(input)
                            }}
                            placeholder={'The description should summarise your collection displayed.'}
                            fontSize={'1.43vh'}
                            disabled={minting}
                          />
                          <S.MintInputError>{descriptionError}</S.MintInputError>
                        </S.MintInfoPanel>
                        <S.PriceInfoPanel disabled={minting}>
                          <S.SellInputPanel>
                            <S.MintLabel disabled={minting}>4. Set a price</S.MintLabel>
                            <S.SellInput>
                              <S.PriceInput
                                value={price}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                                spellCheck="false"
                                autoFocus
                                onChange={(event) => {
                                  const input = event.target.value
                                  setPrice(input)
                                }}
                                disabled={minting}
                                type="number"
                              ></S.PriceInput>
                              <S.SymbolLabel>ETH</S.SymbolLabel>
                            </S.SellInput>
                          </S.SellInputPanel>
                          <S.PriceTip>
                            If you do not enter a price or if the price is 0 ETH, the NFT will not appear in the
                            marketplace. You can also continue to sell this NFT in &apos;My Artwork&apos; later.
                          </S.PriceTip>
                        </S.PriceInfoPanel>
                        <S.CheckInfoPanel>
                          <S.CheckItem
                            onClick={() => {
                              if (minting) return
                              setAllowPrice(!allowPrice)
                            }}
                          >
                            <S.CheckIcon>
                              <Check fill={allowPrice ? theme.text3 : theme.grey1}></Check>
                            </S.CheckIcon>
                            <S.CheckInfo>
                              I acknowledge that AW3C has full discretion as to the selection and pricing strategy of
                              projects deployed on the platform.
                            </S.CheckInfo>
                          </S.CheckItem>
                          <S.CheckItem
                            style={{ marginTop: '1.43vh' }}
                            onClick={() => {
                              if (minting) return
                              setAllowLicense(!allowLicense)
                            }}
                          >
                            <S.CheckIcon>
                              <Check fill={allowLicense ? theme.text3 : theme.grey1}></Check>
                            </S.CheckIcon>
                            <S.CheckInfo>
                              I acknowledge that my project will consist of 100% original content created by myself or
                              my collaborative team and will not contain content that is purchased or requires licensing
                              from third parties.
                            </S.CheckInfo>
                          </S.CheckItem>
                        </S.CheckInfoPanel>
                        <S.MintActionPanel>
                          <S.MintButton disabled={minting} onClick={handleMint}>
                            Submit
                          </S.MintButton>
                        </S.MintActionPanel>
                      </>
                    )}
                  </S.DetailRightPanel>
                </S.DetailInfoPanel>
              )}
            </S.GenDetailPanel>
          </S.GenDetailModal>
        )}
        {mintSuccess && (
          <S.GenSuccessModal>
            <S.DetailBackButton
              onClick={() => {
                setMintSuccess(false)
                clearDetailInfo()
              }}
            >
              Back
            </S.DetailBackButton>
            <S.MintSuccessPanel>
              <S.MintSuccessDetail>
                {selDetail?.type === 'image' && (
                  <S.MintSuccessImagePanel>
                    <img src={selDetail.url} alt="detail" />
                  </S.MintSuccessImagePanel>
                )}
                {selDetail?.type === 'audio' && (
                  <S.MintSuccessMusicPanel>
                    <AudioWavePlayer playSize="10.3vh" source={selDetail.url} />
                  </S.MintSuccessMusicPanel>
                )}
              </S.MintSuccessDetail>
              <S.MintSuccessInfo>
                <S.SuccessTitle>Submit Successful!</S.SuccessTitle>
                <S.SuccessInfo>
                  You can view it on{' '}
                  <S.StyledExtLink
                    href={getExplorerLink(
                      chainId ?? SupportedChainId.MAINNET,
                      `${contract}?a=${tokenId}`,
                      ExplorerDataType.TOKEN
                    )}
                  >
                    Blockchain
                  </S.StyledExtLink>
                  ,
                </S.SuccessInfo>
                <S.SuccessInfo>
                  check it in{' '}
                  <S.StyledInSucccessLink
                    onClick={() => {
                      history.push('/myartwork')
                    }}
                  >
                    your Artwork
                  </S.StyledInSucccessLink>
                  ,
                </S.SuccessInfo>
                <S.SuccessInfo>
                  or simply{' '}
                  <S.StyledInCreateLink
                    onClick={() => {
                      setMintSuccess(false)
                      clearDetailInfo()
                    }}
                  >
                    create
                  </S.StyledInCreateLink>{' '}
                  something amazing again!
                </S.SuccessInfo>
              </S.MintSuccessInfo>
            </S.MintSuccessPanel>
          </S.GenSuccessModal>
        )}
      </S.GeneratePanel>

      {likeList.length > 0 && (
        <S.LikeListPanel>
          <S.LikeLabel>You may also like</S.LikeLabel>
          {/* <InfiniteScroll
            initialLoad={false}
            pageStart={1}
            threshold={250}
            loadMore={() => {
              console.log('loadmore')
              if (throttling.current || !hasMore) {
                return
              }
              throttling.current = true
              setTimeout(() => {
                loadData(cursor, getLoadType())
              }, 200)
            }}
            hasMore={hasMore}
            useWindow={true}
            loader={<div key={2}></div>}
          > */}
          <Masonry columnsCount={width && width <= MEDIA_WIDTHS.upToSmall ? 1 : 3} gutter="2.31vw" key={1}>
            {likeList &&
              likeList.length > 0 &&
              likeList.map((item: any, index: any) => {
                return (
                  <TokenCard
                    key={index}
                    image={item.tokenUrl}
                    onClick={() => {
                      console.log('mediaId', item.medias[0].mediaId)
                      history.push(`/detail/${item.medias[0].mediaId}`)
                    }}
                  ></TokenCard>
                )
              })}
          </Masonry>
          {/* </InfiniteScroll> */}
        </S.LikeListPanel>
      )}
    </PageWrapper>
  )
}
